import React, { useState, useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { mapbox_token, urlapi } from '../../../lib/backend/data';
import mapboxGlDraw from '@mapbox/mapbox-gl-draw';
import { obtenerCentroMapaPorPais } from '../../../lib/helpers/data/internationa';
import { useDispatch, useSelector } from 'react-redux';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import Map, { Layer, Marker, Source } from 'react-map-gl';
import * as turf from '@turf/turf';
import { calcularPuntoCentral, procesarCoordenadasParaCentroMapa } from '../../../lib/helpers/maps/geocercas';
import { Button, ButtonGroup, Card, Col, Dropdown, Row, Form, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import polyline from '@mapbox/polyline';
import { cerrarSesion } from '../../../redux/actions/session';
import SelectorZonaAvanzado from '../selector_zona_avanzado';
import { BiDotsVerticalRounded, BiTargetLock, BiCheckboxChecked, BiCheckbox } from 'react-icons/bi';
import { FiMapPin } from 'react-icons/fi';
import { BsFillTrashFill } from 'react-icons/bs';
import IngresoDirecciones from '../../Zonas/direcciones/ingresa_direcciones';
import { recuperarCoordenadas } from '../../../lib/helpers/geo';
import AgregarPunto from '../../Zonas/direcciones/agregar_punto';

mapboxgl.accessToken = mapbox_token
const dropdownStyles = {
  '&::after': {
    display: 'none', // Esta regla oculta el indicador de flecha
  },
};

const MapaDibujoPoligonoAvanzado = (props) => {
  const {
    default_geojson,
    height,
    redzone
  } = props
  const pais = useSelector(state => state.pais)
  const initialViewport = obtenerCentroMapaPorPais(pais)
  const [geometrias, setGeometrias] = useState([]);
  const session = useSelector(state => state.miusuario)
  const initialViewState = obtenerCentroMapaPorPais(pais)
  const [viewState, setViewState] = useState(initialViewState);
  const [ loadingGeocerca, setLoadingGeocerca ] = useState(false)
  const [geocercaSeleccionada, setGeocercaSeleccionada] = useState(false);
  const [seleccionadaEliminar, setSeleccionadaEliminar] = useState(0)
  const [ focusOnMap, setFocusOnMap ] = useState(false)
  const dispatch = useDispatch()
  const [ coleccion, setColeccion ] = useState( default_geojson ? default_geojson :{
    type: 'FeatureCollection',
    features: []
  })
    const mapRef = useRef(null);
    const drawRef = useRef(null);
    const controls = []
    const geocercas = useRef([])

    const handleGeocoderResult = (event) => {
      if (event.result && event.result.geometry && event.result.geometry.coordinates) {
        const [lng, lat] = event.result.geometry.coordinates;
        setViewState({ ...viewState, longitude: lng, latitude: lat, zoom: 12 });
      }
    };

    const ajustarMapaAGeocercas = (datos_procesar) => {

      let data = datos_procesar ? datos_procesar : coleccion.features
      const instancia_geocercas = JSON.parse( JSON.stringify( data ))
      const coordinates = instancia_geocercas.reduce((acc, geofence) => {
        const geofenceCoords = recuperarCoordenadas(geofence.geometry.coordinates)
        return [...acc, ...geofenceCoords];
      }, []);
      
      if (coordinates.length > 0) {
        
          const points = coordinates.map(marker => turf.point([marker[0], marker[1]]));
          const collection = turf.featureCollection(points);
          const bounds = turf.bbox(collection);

          const newViewport = {
              ...viewState,
              latitude: (bounds[1] + bounds[3]) / 2,
              longitude: (bounds[0] + bounds[2]) / 2
          };

          const options = {
            padding: 30 // Ajusta el valor de padding según tus necesidades
          };

          setViewState(newViewport);
          mapRef.current?.fitBounds(bounds, options);
    }
    }

    const mostrarSeleccionadas = () => {
      const seleccionadas = geocercas.current.filter(geo => geo.seleccionado === true).length
      return setSeleccionadaEliminar(seleccionadas)      
    }
  
    const handleMapLoad = () => {

        const geocoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl,
            marker: false, // Desactivar el marcador de ubicación seleccionada
            placeholder: "Busca una dirección",
            countries: pais
          });
          
          // Manejar el evento de resultado de búsqueda
          geocoder.on('result', handleGeocoderResult);
    
          let instancia_creacion = {
            displayControlsDefault: false,
            defaultMode: 'draw_polygon',
            controls: {
              polygon: true, // Habilitar dibujo de polígonos
              trash: true, // Habilitar la herramienta de borrado
            }
          }
          if(default_geojson) instancia_creacion.data = default_geojson
          const dibujo = new mapboxGlDraw(instancia_creacion);
    
          drawRef.current = dibujo; // Guardar la referencia del objeto draw en el ref
  
          
        if(!controls.includes('geocode')) mapRef.current?.addControl(geocoder, "top-right"); // Agregar el controlador de búsqueda en la esquina superior derecha
        controls.push('geocode')

        if(!controls.includes('draw')) mapRef.current?.addControl(dibujo, 'top-left'); // Agregar la herramienta de dibujo en la esquina superior izquierda
        controls.push('draw')

        // Asegurarse de que el mapa esté completamente cargado antes de acceder a drawRef.current
        mapRef.current?.on('draw.create', handleDraw); // Evento al crear un polígono
        mapRef.current?.on('draw.update', handleDraw); // Evento al actualizar un polígono
        mapRef.current?.on('draw.delete', handleDraw); // Evento al borrar un polígono
        mapRef.current?.resize();

    }

    const flattenArray = (arr) => {
      return arr.reduce((result, current) => {
        if (Array.isArray(current[0])) {
          result.push(...flattenArray(current));
        } else {
          result.push(current);
        }
        return result;
      }, []);
    };
    

    useEffect(() => {
      if(mapRef) if(mapRef.current) mapRef.current.resize()
      ajustarMapaAGeocercas()
    }, [ coleccion ] )

    const onAgregarZona = (data) => {

      const buscar = geocercas.current.findIndex(geo => geo.id_geocerca === data._id)
        if(buscar > -1) return toast.error("Esta geocerca ya fue agregada")
          const nueva = {
              id_geocerca: data._id,
              titulo: data.titulo,
              type: "Feature",
              properties: data.properties,
              geometry: {
                type: data.region.type,
                coordinates: data.region.coordinates
              }
            }
            console.log(nueva)
            // console.log("GEO_CURRENT:", [...geocercas.current, ...[nueva]] )
            const nuevo_arreglo = [...geocercas.current, ...[nueva]]
            
            if (drawRef.current) {
              const data = drawRef.current.getAll(); // Obtener todas las geometrías dibujadas
              // const geocercas_juntas = [...data.features, ...nuevo_arreglo] // ASI ESTABA
              const geocercas_juntas = [...nuevo_arreglo]
              console.log({geocercas_juntas})
              const nueva_collection = {
                  features: geocercas_juntas
              }
              
              if(props.onChange) props.onChange(nueva_collection)
            }

              geocercas.current = nuevo_arreglo
              setColeccion((prevCollection) => ({
                ...prevCollection,
                features: nuevo_arreglo,
              }))
        }

    const handleDraw = () => {
      if (drawRef.current) {
        const data = drawRef.current.getAll(); // Obtener todas las geometrías dibujadas
        const geocercas_juntas = [...data.features, ...geocercas.current]

        const collection = {
            features: geocercas_juntas
        }
        
        if(props.onChange) props.onChange(collection)

        drawRef.current.deleteAll()
        geocercas.current = geocercas_juntas
        setColeccion((prevCollection) => ({
          ...prevCollection,
          features: geocercas_juntas,
        }))
      }
    };

    const centrarMapa = (centro, max_zoom) => {
      if(!centro) return false
      if(!centro.longitude || !centro.latitude) return toast.info("Geocerca multi polígono está en desarrollo")
      if(isNaN(centro.longitude) || isNaN(centro.latitude)) return toast.info("Geocerca multi polígono está en desarrollo")
      const nuevo_zoom = initialViewState.zoom + (max_zoom ? max_zoom : 0)
      const nuevo_centro = { center: [centro.longitude, centro.latitude], zoom: nuevo_zoom, duration: 2000}
      mapRef.current?.flyTo(nuevo_centro);
      setTimeout(() => {
        setViewState({ ...viewState, longitude: centro.longitude, latitude: centro.latitude, zoom: nuevo_zoom });
      }, 2010);
    }

    const resetMapView = () => {
      if(!focusOnMap) return false
      return <Button size="small" onClick={() => {
        setFocusOnMap(false)
        ajustarMapaAGeocercas()
      }}>VOLVER A MAPA COMPLETO</Button>
    }

    const handleChangeGeocerca = (e, id) => {
      const { name, value } = e.target
      const pos = e.target.getAttribute('pos')
      geocercas.current[pos][name] = value

      const nueva_collection = {
        features: geocercas.current
    }
    
    if(props.onChange) props.onChange(nueva_collection)      
    }

    const eliminarGeocerca = (i) => {
      const instancia_geocerca = JSON.parse( JSON.stringify(geocercas.current))
      instancia_geocerca.splice(i,1)
      const nueva_collection = {
        features: instancia_geocerca
      }


      const instancia_coleccion = JSON.parse( JSON.stringify( coleccion ))
      instancia_coleccion.features = [...[], ...instancia_geocerca]
      geocercas.current = instancia_geocerca
      setColeccion(prev => ({...{}, ...instancia_coleccion }))
      if(props.onChange) props.onChange(nueva_collection) 
    }

    const mostrarGeocercas = () => {
      const iconoTarget = <BiTargetLock />
      if(geocercas.current.length < 1 ) return <div className='mb-3 mt-3'><h6 className='mb-0 mt-0' level={4}>Sin puntos de control cargados {iconoTarget}</h6></div>
      
      return <div>
        <div className='mb-3 mt-3'><h6 className='mb-0 mt-0' level={4}>{geocercas.current.length} puntos de control cargados {iconoTarget}</h6></div>
        { seleccionadaEliminar > 0 ? <div className='mb-3 mt-3'><h6 className='mb-0 mt-0' level={4}>{seleccionadaEliminar} geocercas seleccionadas {iconoTarget}</h6></div> : false }
        {accionesGeocercas()}
        {resetMapView()}
          {
            geocercas.current.map((geo,pos) => {

              const coords = procesarCoordenadasParaCentroMapa(geo.geometry.coordinates)
              if(coords.length < 1) return false
              const centro = calcularPuntoCentral(coords)
              
              return <div key={geo._id} className='mb-2'>
              <Card className='p-2'>
              <Row className='align-items-center'>
                <Col md={9}>
                  <span className='d-block' style={{ fontSize:10, color: "gray" }}>Nombre</span>
                  <input style={{ border: "none", width: "100%", marginBottom: 0 }} mb={3} placeholder="Nombre" name="titulo" pos={pos} value={ geo.titulo ? geo.titulo : `GEO ${pos+1}` } onChange={(e) => handleChangeGeocerca(e,geo._id)} />
                  <input style={{ border: "none", width: "100%", fontSize: 12 }} mb={3} placeholder="Escribe aquí tus observaciones" name="observaciones" pos={pos} value={ geo.observaciones } onChange={(e) => handleChangeGeocerca(e,geo._id)} />
                </Col>
                <Col md={1} className='p-0'>
                <Dropdown style={dropdownStyles}>
                   <Dropdown.Toggle variant="white" style={{ padding:0, lineHeight: 0 }} >{ geo.deleting ? <Spinner animation='border' size="sm" /> :      <BiDotsVerticalRounded size={25} />}</Dropdown.Toggle>
                      <Dropdown.Menu style={{ fontSize: 14, fontWeight: 400, color: '#1B3665' }}>
                       <Dropdown.Item eventKey="2" onClick={() => centrarMapa(centro)}><FiMapPin /> VER EN MAPA</Dropdown.Item>
                       <Dropdown.Item onClick={() => eliminarGeocerca(geo._id)} disabled={geo.deleting === true ? true : false} eventKey="1"><BsFillTrashFill /> {geo.deleting === true ? "ESPERA UN MOMENTO..." : "ELIMINAR"}</Dropdown.Item>
                      </Dropdown.Menu>
                </Dropdown>                
                </Col>
                <Col md={1} >

                <Row className='align-items-center'>
                  <div  style={{ border: "none", width: "100%", fontSize: 35 }} className='pb-2 m-0' onClick={() => {
                      let actual = geo
                      actual.seleccionado = actual.seleccionado === true ? false : true
                      // console.log("geocerca_pos", actual )
                      // console.log("geocercas", geocercas.current)
                      mostrarSeleccionadas()
                      return actual}}>{ geo.seleccionado === true ? <BiCheckboxChecked /> : <BiCheckbox  /> } </div>
               
                  </Row> 
                      
                  </Col>
              </Row>
                </Card>
                </div>
            })
          }
      </div>
    }

    const fetchZone = async () => {
      if(!geocercaSeleccionada) return false
      setLoadingGeocerca(true)
      const url = `${urlapi}/geodata/zonas/geocerca?id=${geocercaSeleccionada._id}`
      return fetch(url, {
        method: "GET",
        headers: {
            'Content-type': "application/json",
            'Authorization': `Bearer: ${session.tokenSession}`,
        }
    })
    .then(res => {
        if(res.status === 401) return dispatch(cerrarSesion())
        return res.json()
    })
    .then(async res => {
        if(!res){
            toast.error("Sin datos obtenidos")
        } else if(res.errorMessage){
            toast.error(res.errorMessage)
        } else if(res._id){
            onAgregarZona(res)
        }
        return setLoadingGeocerca(false)
    })
    .catch(error => {
        toast.error("No se pudo efectuar la operación")
        return setLoadingGeocerca(false)
    })
    }

    const onSelectPoint = (data) => {
      const { coordenadas, route, puntos, geometries } = data
      // ajustarMapaAGeocercas(coordenadas)
      // const nuevas_geometrias = [...geometrias, ...geometries]
      // setGeometrias(prev => [...prev, ...geometries.map(geo => geo.geometry)])
      // if(props.onChangeGeometries) props.onChangeGeometries(nuevas_geometrias)
      const nuevo_arreglo = [...geocercas.current, ...coordenadas]
              
              if (drawRef.current) {
                const data = drawRef.current.getAll(); // Obtener todas las geometrías dibujadas
                const geocercas_juntas = [...data.features, ...nuevo_arreglo]
                console.log({geocercas_juntas})
                const nueva_collection = {
                    features: geocercas_juntas
                }
                
                if(props.onChange) props.onChange(nueva_collection)
              }
  
                geocercas.current = nuevo_arreglo
                setColeccion((prevCollection) => ({
                  ...prevCollection,
                  features: nuevo_arreglo,
                }))
    }

    const onSelectRoute = (data) => {
      const { coordenadas, route, puntos, geometries } = data
      ajustarMapaAGeocercas(coordenadas)
      const nuevas_geometrias = [...geometrias, ...geometries]
      setGeometrias(prev => [...prev, ...geometries.map(geo => geo.geometry)])
      if(props.onChangeGeometries) props.onChangeGeometries(nuevas_geometrias)
      const nuevo_arreglo = [...geocercas.current, ...coordenadas]
              
              if (drawRef.current) {
                const data = drawRef.current.getAll(); // Obtener todas las geometrías dibujadas
                const geocercas_juntas = [...data.features, ...nuevo_arreglo]
                console.log({geocercas_juntas})
                const nueva_collection = {
                    features: geocercas_juntas
                }
                
                if(props.onChange) props.onChange(nueva_collection)
              }
  
                geocercas.current = nuevo_arreglo
                setColeccion((prevCollection) => ({
                  ...prevCollection,
                  features: nuevo_arreglo,
                }))
    }
    
    const eliminarTodasLasGeocercas = () => {
      geocercas.current = []
      const eliminadas = setColeccion((prevCollection) => ({
        ...prevCollection,
        features: [],
      }))
      mostrarSeleccionadas()
      return eliminadas
    }

    const eliminarGeocercasSeleccionadas = () => {   
       const instancia_geocerca = JSON.parse( JSON.stringify(geocercas.current))
      let nuevas = instancia_geocerca.filter(geo => geo.seleccionado !== true)
      geocercas.current = nuevas
      const nuevasGeo = setColeccion((prev) =>({
        ...prev,
         features: [...[], ...nuevas] })) 

      if(props.onChange) props.onChange({ features: geocercas.current }) 
      mostrarSeleccionadas()   
      return nuevasGeo
    }   

    const accionesGeocercas = () => {
      if(!geocercas) return false
      if(!geocercas.current) return false
      return <div>
        <Button size="sm" variant='light' className='w-100 mb-3' onClick={() => eliminarTodasLasGeocercas()} ><BsFillTrashFill /> LIMPIAR GEOCERCAS</Button>
      </div>
    }

    const mostrarBotonDeEliminarVarios = () => {
      if(geocercas.current.length < 1 ) return false
      if(!geocercas) return false
      if(!geocercas.current) return false
      if(!seleccionadaEliminar) return false
      return <div>
         <Button size="sm" variant='light' className='w-100 mb-3' onClick={() => eliminarGeocercasSeleccionadas()} ><BsFillTrashFill /> ELIMINAR SELECCIÓN</Button>
      </div>
    }
  
    return (<div style={{ height: '100vh', width: '100%' }}>
      <Row>
        <Col md={5} className='pt-3' style={{ height: "90vh", overflowY: "scroll", background: "white" }}>
          <SelectorZonaAvanzado condicion={{ local_name: "region" }} onSelected={(data) => onAgregarZona(data)} />
          <AgregarPunto onSelectPoint={(data) => onSelectPoint(data)} />
          <IngresoDirecciones onSelectRoute={(data) => onSelectRoute(data)} />
        {mostrarBotonDeEliminarVarios()}
        {mostrarGeocercas()}
        </Col>
        <Col md={7}>
        <Map
              ref={mapRef}
              {...viewState}
              onMove={evt => setViewState(evt.viewState)}
              style={{width: "100%", height: height ? height : 500}}
              mapStyle="mapbox://styles/mapbox/streets-v9"
              mapboxAccessToken={mapbox_token}
              onLoad={handleMapLoad}
            >

{
            geometrias.map((geometry,ir) => {

                const decodedCoords = polyline.decode(geometry)
                
                return <Source
                    id={`route-${ir}`}
                    type='geojson'
                    data={{
                        type: 'Feature',
                        properties: {},
                        geometry: {
                            type: "LineString",
                            coordinates: decodedCoords.map(co => [co[1], co[0]])
                        }
                      }}
                >
                    <Layer
                    id={`route-layer-${ir}`}
                    type="line"
                    layout={{
                        'line-join': 'round',
                        'line-cap': 'round',
                      }}                
                    paint={{
                        'line-color': 'black',
                        'line-width': 4,
                        'line-opacity': 0.75
                    }}
                />
                </Source>
            })
        }
                  <Source
                          key={"ajsfkn"}
                          id={"geocerca._id"}
                          type="geojson"
                          data={coleccion}
                          >
                              
                              {
                                redzone === true ? false : <Layer
                                id="geocercas-layer"
                                type="line"
                                paint={{
                                    'line-width': 4,
                                    'line-color': '#0080ef'
                                  }}                      
                                />
                              }
                              {
                                redzone === true ? false : <Layer
                                id="geocercas-layer"
                                type="fill"
                                paint={{
                                    'fill-color': 'black',
                                    'fill-opacity': 0.5
                                  }}                      
                                />
                              }
                                  {
                                    redzone === true ? <Layer
                                    id="geocercas-layer"
                                    type="fill"
                                    paint={{
                                        'fill-color': 'red',
                                        'fill-opacity': 0.2
                                      }}                      
                                    /> : false
                                  }
                          </Source>
            </Map>
        </Col>
      </Row>
      </div>);
  };
  

export default MapaDibujoPoligonoAvanzado