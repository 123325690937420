import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { urlapi } from '../../../lib/backend/data';
import { useSelector } from 'react-redux';
import { Badge, Col, Spinner } from 'react-bootstrap';

const CODIGOS_INTEGRACIONES = {
    CONFIRMA_FACIL: "ConfirmaFacil",
    MARKETPLACE_TREMUS: "Marketplace Tremus"
}

const ImportadorIntegraciones = (props) => {
    const {
        permitir
    } = props
    const creador = props.creador
    const [ integraciones, setIntegraciones ] = useState([])
    const [ integracionActiva, setIntegracionActiva ] = useState(false)
    const [ configIntegracionActiva, setConfigIntegracionActiva ] = useState(false)
    const [ loadingConfig, setLoadingConfig ] = useState(false)
    const [ loadingDatos, setLoadingDatos ] = useState(false)
    const [ datosImportados, setDatosImportados ] = useState(false)
    const [ loading, setLoading ] = useState(true)
    const [ showForm, setShowForm ] = useState(false)
    const { tokenSession, data } = useSelector(state => state.miusuario)
    const permitirSeleccionarIntegracion = typeof permitir === "undefined" ? true : permitir


    const corregirTelefono = (num) => {
        if(!num){
          return "(en blanco)"
        }
        const digito = num.toString()
        const longitud = digito.length
        if(longitud === 0){
          return "(en blanco)"
        }
        const numero = digito.replace('+56', '56')
        if(longitud === 9){
          return `56${numero}`
        } else if(longitud === 8){
          return `569${numero}`
        }
        return numero
    }

    const exportarDatos = (datos) => {

        if(props.exportarDatos) {
            setIntegracionActiva(false)
            setDatosImportados(false)
            setConfigIntegracionActiva(false)
            return props.exportarDatos(datos)
        }
        return false
    }

    const seleccionarIntegracion =  async (id) => {
        if(!permitirSeleccionarIntegracion) return toast.error("Selecciona primero un transporte")
        setLoadingConfig(true)
        const i = integraciones.findIndex(integ => integ._id === id)
        const seleccionada = integraciones[i]
        const datos = await consultarConfigIntegracion(seleccionada.descripcion)
        setConfigIntegracionActiva(datos)
        setIntegracionActiva(id)
        return setLoadingConfig(false)
    }

    useEffect(() => {
        consultarIntegraciones()
    }, [])

    const handleNewRecord = (payload) => {
        if(props.handleNewRecord) return props.handleNewRecord(payload)
    }

    const handleChangeConfig = (e) => {
        const { name, value } = e.target
        const i = integraciones.findIndex(integ => integ._id === integracionActiva)
        if(!integraciones[i].formulario) integraciones[i].formulario = {}
        integraciones[i].formulario[name] = value
        return setIntegraciones(integraciones)
    }

    const consultarConfigIntegracion = async (nombre) => {
        const url = `${urlapi}/importador/config-by-integracion?nombre=${nombre}`
        return fetch(url,{
            method: "GET",
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${tokenSession}`
            }
        })
        .then(pros => pros.json())
        .then(pros => {
            if(!pros){
                toast.error('Ocurrió un error inesperado, Sin respuesta del servidor')
            } else if(pros.errorMessage){
                toast.error(pros.errorMessage)
            } else if(Array.isArray(pros) !== false){
                return pros
            }
            return false
        })
        .catch(error => {
            toast.error('Ocurrió un error inesperado')
            return false
        })
    }

    const importarDatosBeetrack = async () => {
        const i = integraciones.findIndex(integ => integ._id === integracionActiva)
        const seleccionada = integraciones[i]
        if(!seleccionada.formulario) return toast.error('Llena el formulario de configuración de esta integración')
        const transporte = seleccionada.formulario.transporte
        const fecha = seleccionada.formulario.fecha
        if(!fecha) return toast.error('fecha es requerida')
        if(!transporte) return toast.error('transporte es requerida')
        const url = `${urlapi}/importador/beetrack/routes`
        setLoadingDatos(true)
        return fetch(url, {
            method:'POST',
            body: JSON.stringify(seleccionada.formulario),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${tokenSession}`
            }
        })
        .then(pros => pros.json())
        .then(pros => {
            if(!pros){
                toast.error('Ocurrió un error inesperado, Sin respuesta del servidor')
                return setLoadingDatos(false)
            } else if(pros.errorMessage){
                toast.error(pros.errorMessage)
                return setLoadingDatos(false)
            } else if(pros._id){
                if(Array.isArray(pros.payload) !== false){
                    toast.success('Datos importados')
                    setDatosImportados(pros)
                } else if(pros.payload.errorMessage){
                    toast.error(pros.payload.errorMessage)
                }
            }
            setLoadingDatos(false)
        })
        .catch(error => {
            toast.error('Ocurrió un error inesperado')
            setLoadingDatos(false)
        })
    }

    const importarDatosMarketplace = async () => {
        const i = integraciones.findIndex(integ => integ._id === integracionActiva)
        const seleccionada = integraciones[i]
        if(!seleccionada.formulario) return toast.error('Llena el formulario de configuración de esta integración')
        const fecha = seleccionada.formulario.fecha
        if(!fecha) return toast.error('fecha es requerida')
        const url = `${urlapi}/importador/marketplace-tremus/routes`
        setLoadingDatos(true)
        return fetch(url, {
            method:'POST',
            body: JSON.stringify({ ...seleccionada.formulario, id: integracionActiva }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${tokenSession}`
            }
        })
        .then(pros => pros.json())
        .then(pros => {
            if(!pros){
                toast.error('Ocurrió un error inesperado, Sin respuesta del servidor')
                return setLoadingDatos(false)
            } else if(pros.errorMessage){
                toast.error(pros.errorMessage)
                return setLoadingDatos(false)
            } else if(pros._id){
                if(Array.isArray(pros.payload) !== false){
                    toast.success('Datos importados')
                    setDatosImportados(pros)
                } else if(pros.payload.errorMessage){
                    toast.error(pros.payload.errorMessage)
                }
            }
            setLoadingDatos(false)
        })
        .catch(error => {
            toast.error('Ocurrió un error inesperado')
            setLoadingDatos(false)
        })
    }
    const importarDatosConfirmafacil = async () => {
        const i = integraciones.findIndex(integ => integ._id === integracionActiva)
        const seleccionada = integraciones[i]
        console.log({seleccionada})
        if(!seleccionada.formulario) return toast.error('Llena el formulario de configuración de esta integración')
        const fecha_desde = seleccionada.formulario.fecha_desde
        const fecha_hasta = seleccionada.formulario.fecha_hasta
        if(!fecha_desde) return toast.error('fecha_desde es requerida')
        if(!fecha_hasta) return toast.error('fecha_hasta es requerida')
        const url = `${urlapi}/importador/confirmafacil/routes`
        setLoadingDatos(true)
        return fetch(url, {
            method:'POST',
            body: JSON.stringify(seleccionada.formulario),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${tokenSession}`
            }
        })
        .then(pros => pros.json())
        .then(pros => {
            if(!pros){
                toast.error('Ocurrió un error inesperado, Sin respuesta del servidor')
                return setLoadingDatos(false)
            } else if(pros.errorMessage){
                toast.error(pros.errorMessage)
                return setLoadingDatos(false)
            } else if(pros._id){
                if(pros.payload){
                    toast.success('Datos importados')
                    let datos_nuevos = {
                        payload: pros.payload?.respostas,
                        paginas: pros.payload?.paginas
                    }
                    setDatosImportados(datos_nuevos)
                } else if(pros.payload.errorMessage){
                    toast.error(pros.payload.errorMessage)
                }
            }
            setLoadingDatos(false)
        })
        .catch(error => {
            toast.error('Ocurrió un error inesperado')
            setLoadingDatos(false)
        })
    }

    const importarDatos = async (nombre) => {
        const i = integraciones.findIndex(integ => integ._id === integracionActiva)
        const seleccionada = integraciones[i]
        switch (seleccionada.descripcion) {
            case 'Beetrack':
                return importarDatosBeetrack()
            case CODIGOS_INTEGRACIONES.CONFIRMA_FACIL:
                return importarDatosConfirmafacil()
            case CODIGOS_INTEGRACIONES.MARKETPLACE_TREMUS:
                return importarDatosMarketplace()
            default:
                break;
        }
    }

    const validateEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }

    const marcarRutaImportar = (i) => {
        datosImportados.payload[i].seleccionada = datosImportados.payload[i].seleccionada === true ? false : true
        console.log("MarcarRuta", i)
        return setDatosImportados({...{}, ...datosImportados})
    }

    const importarSeleccion = () => {
        const i = integraciones.findIndex(integ => integ._id === integracionActiva)
        const seleccionada = integraciones[i]
        switch (seleccionada.descripcion) {
            case 'Beetrack':
                
                let datos_importar = []
                const seleccionadas = datosImportados.payload.filter(r => r.seleccionada === true).length
                if(seleccionadas < 1) return toast.error("No has seleccionado ninguna ruta")
                for( const ruta of datosImportados.payload.filter(r => r.seleccionada === true) ){
                    for( const despacho of ruta.dispatches){
                        const productos = despacho.items ? despacho.items : []

                        let identificador = despacho.identifier
                        const i_tag = despacho.tags.findIndex(ta => {
                            const llaves = Object.keys(ta)
                            if(llaves.filter(lla => lla === 'ENTREGA').length > 0) return true
                            return false
                        })
                        if(i_tag > -1) identificador = despacho.tags[i_tag].ENTREGA
                        const contar_bultos = despacho.items.reduce((prev, next) => {
                            let cantidad = parseInt(next.quantity)
                            if(isNaN(cantidad)) cantidad = 1
                            return prev + cantidad
                        },0)
                        const notas = productos.map(p => `${p.quantity} ${p.name}`).join(', ')
                        
                        let nuevo_registro = {
                            referencia: identificador,
                            vehiculo: '',
                            productos: productos.map(pro => ({ 
                                description: pro.description,
                                quantity: pro.quantity,
                                codigo_item: pro.code
                            })),
                            identificador_contacto: despacho.contact_id,
                            nombre_contacto: despacho.contact_name,
                            telefono: corregirTelefono(despacho.contact_phone),
                            email_contacto: validateEmail(despacho.contact_email) !== true ? '' : despacho.contact_email,
                            direccion: despacho.contact_address,
                            observaciones_direccion: notas,
                            nota: notas,
                            fecha_min_entrega: '',
                            fecha_max_entrega: '',
                            bultos: contar_bultos,
                            meta_data: [{
                                key: "guia",
                                value: despacho.identifier ? despacho.identifier.toString() : ""
                            }]
                        }


                        if(despacho.tags){
                            if(Array.isArray(despacho.tags) !== false){
                                if(despacho.tags.length > 0){
                                    for( const meta of despacho.tags ){
                                        Object.keys(meta).map(key => {
                                            const llave = key.toLowerCase()
                                            if(meta[key]){
                                                nuevo_registro.meta_data.push({
                                                    key: llave,
                                                    value: meta[key]
                                                })
                                            }
                                        })

                                    }
                                }
                            }
                        }
                        console.log(nuevo_registro)
                        datos_importar.push(nuevo_registro)
                    
                    }
                }
                
                return exportarDatos(datos_importar)
          
                case CODIGOS_INTEGRACIONES.CONFIRMA_FACIL:
                    let datos_importar_confirmafacil = []
                   
                    const seleccionadas_ = datosImportados.payload.filter(r => r.seleccionada === true).length
                
                if(seleccionadas_ < 1) return toast.error("No has seleccionado ninguna ruta")                
                for( const ruta of datosImportados.payload.filter(r => r.seleccionada === true) ){
                    let identificador = ruta.numero.toString()

                        const productos = ruta.items ? ruta.items : []
                        const notas = productos.map(p => `${p.quantidade} ${p.descricao}`).join(', ')
                        const contar_bultos = ruta.items.reduce((prev, next) => {
                            let cantidad = parseInt(next.quantidade)
                            if(isNaN(cantidad)) cantidad = 1
                            return prev + cantidad
                        },0)
                        let nuevo_registro_confirmafacil = {
                            referencia: identificador,
                            vehiculo: '',
                            productos: productos.map(pro => ({ 
                                description: pro.descricao,
                                quantity: pro.quantidade,
                                codigo_item: pro.codigo
                            })),
                            identificador_contacto: ruta.destinatario.cnpj,
                            nombre_contacto: ruta.destinatario.nome,
                            telefono: corregirTelefono(ruta.destinatario.celular),
                            email_contacto: validateEmail(ruta.destinatario.email) !== true ? '' : ruta.destinatario.email,
                            direccion: `${ruta.endereco.logradouro}, ${ruta.endereco.numero}, ${ruta.endereco.cidade}, ${ruta.endereco.uf}`,
                            observaciones_direccion: notas,
                            nota: notas,
                            fecha_min_entrega: '',
                            fecha_max_entrega: '',
                            bultos: contar_bultos,
                            meta_data: [{
                                key: "guia",
                                value: ruta.idEmbarque ? ruta.idEmbarque.toString() : ""
                            }]
                        }                    

                        console.log(nuevo_registro_confirmafacil)
                        datos_importar_confirmafacil.push(nuevo_registro_confirmafacil)
                    }

                    return exportarDatos(datos_importar_confirmafacil)
    
            default:
                break;
        }
    }

    const importarTodo = () => {
        const i = integraciones.findIndex(integ => integ._id === integracionActiva)
        const seleccionada = integraciones[i]
        switch (seleccionada.descripcion) {
            case 'Beetrack':
                
                let datos_importar = []

                console.log(datosImportados.payload)

                for( const ruta of datosImportados.payload ){

                    for( const despacho of ruta.dispatches){

                        const productos     = despacho.items ? despacho.items : []
                        let identificador   = despacho.identifier

                        const i_tag = despacho.tags.findIndex(ta => {
                            const llaves = Object.keys(ta)
                            if(llaves.filter(lla => lla === 'ENTREGA').length > 0) return true
                            return false
                        })

                        if(i_tag > -1) identificador = despacho.tags[i_tag].ENTREGA
                        const contar_bultos = despacho.items.reduce((prev, next) => {
                            let cantidad = parseInt(next.quantity)
                            if(isNaN(cantidad)) cantidad = 1
                            return prev + cantidad
                        },0)

                        const notas = productos.map(p => `${p.quantity} ${p.name}`).join(', ')
                        
                        let nuevo_registro = {
                            referencia: identificador,
                            vehiculo: '',
                            productos: productos.map(pro => ({ 
                                description: pro.description,
                                quantity: pro.quantity,
                                codigo_item: pro.code
                            })),
                            identificador_contacto: despacho.contact_id,
                            nombre_contacto: despacho.contact_name,
                            telefono: corregirTelefono(despacho.contact_phone),
                            email_contacto: validateEmail(despacho.contact_email) !== true ? '' : despacho.contact_email,
                            direccion: despacho.contact_address,
                            observaciones_direccion: notas,
                            nota: notas,
                            fecha_min_entrega: '',
                            fecha_max_entrega: '',
                            bultos: contar_bultos,
                            meta_data: [{
                                key: "guia",
                                value: despacho.identifier ? despacho.identifier.toString() : ""
                            }]
                        }

                        if(despacho.tags){
                            if(Array.isArray(despacho.tags) !== false){
                                if(despacho.tags.length > 0){
                                    for( const meta of despacho.tags ){
                                        Object.keys(meta).map(key => {
                                            const llave = key.toLowerCase()
                                            if(meta[key]){
                                                nuevo_registro.meta_data.push({
                                                    key: llave,
                                                    value: meta[key]
                                                })
                                            }
                                        })
                                    }
                                }
                            }
                        }

                        datos_importar.push(nuevo_registro)

                    }
                }

                return exportarDatos(datos_importar)
          
            case CODIGOS_INTEGRACIONES.CONFIRMA_FACIL:
                    let datos_importar_confirmafacil = []

                    let ocs = []
                    let ocs_duplicados = []
                    let migrar = []
                   
                for( const ruta of datosImportados.payload ){

                        let identificador = ruta.numero.toString()

                        const productos = ruta.items ? ruta.items : []
                        
                        const notas = productos.map(p => `${p.quantidade} ${p.descricao}`).join(', ')

                        const contar_bultos = ruta.items.reduce((prev, next) => {
                            let cantidad = parseInt(next.quantidade)
                            if(isNaN(cantidad)) cantidad = 1
                            return prev + cantidad
                        },0)

                        let nuevo_registro_confirmafacil = {
                            referencia: identificador,
                            vehiculo: '',
                            productos: productos.map(pro => ({ 
                                description: pro.descricao,
                                quantity: pro.quantidade,
                                codigo_item: pro.codigo
                            })),
                            identificador_contacto: ruta.destinatario.cnpj,
                            nombre_contacto: ruta.destinatario.nome,
                            telefono: corregirTelefono(ruta.destinatario.celular),
                            email_contacto: validateEmail(ruta.destinatario.email) !== true ? '' : ruta.destinatario.email,
                            direccion: `${ruta.endereco.logradouro}, ${ruta.endereco.numero}, ${ruta.endereco.cidade}, ${ruta.endereco.uf}`,
                            observaciones_direccion: notas,
                            nota: notas,
                            fecha_min_entrega: '',
                            fecha_max_entrega: '',
                            bultos: contar_bultos,
                            meta_data: [{
                                key: "guia",
                                value: ruta.idEmbarque ? ruta.idEmbarque.toString() : ""
                            }]
                        }

                        if(ruta.pedido) if(typeof ruta.pedido === 'object') if(ruta.pedido.numero) {

                            const oc_ = ruta.pedido.numero.toString()
                            migrar.push({ key: "oc", value: oc_, pedido: identificador })
                            if(ocs.includes(oc_)){
                                ocs_duplicados.push(oc_)
                            } else {
                                ocs.push(oc_)
                            }
                            nuevo_registro_confirmafacil.meta_data.push({ key: "oc", value: oc_ })
                        }

                        datos_importar_confirmafacil.push(nuevo_registro_confirmafacil)
                    }

                    const duplicados = datos_importar_confirmafacil.filter(pe => {
                        const {
                            meta_data
                        } = pe
                        const data = meta_data.filter(m => m.key === 'oc' && ocs_duplicados.includes(m.value)).length
                        if(data > 0) return true
                        return false
                    })
                    console.log({ ocs, ocs_duplicados, duplicados, migrar })
                    
                return exportarDatos(datos_importar_confirmafacil)
            
            case CODIGOS_INTEGRACIONES.MARKETPLACE_TREMUS:
                let datos_marketplace = []
                   
                for( const ruta of datosImportados.payload ){

                    if(ruta.ordenes) if(Array.isArray(ruta.ordenes) !== false) if(ruta.ordenes.length > 0){
                        for( const orden of ruta.ordenes ){

                            const pedido = orden?.payload
                            if(!pedido) continue
                            if(typeof pedido !== "object") continue

                            const identificador = pedido.id.toString()

                            const contar_bultos = orden.line_items.reduce((prev, next) => {
                                let cantidad = parseInt(next.quantity)
                                if(isNaN(cantidad)) cantidad = 1
                                return prev + cantidad
                            },0)

                            let nuevo_registro_confirmafacil = {
                                referencia: identificador,
                                vehiculo: '',
                                productos: orden.line_items.map(pro => ({ 
                                    description: pro.name,
                                    quantity: pro.quantity,
                                    codigo_item: pro.sku
                                })),
                                identificador_contacto: "",
                                nombre_contacto: pedido?.billing?.first_name,
                                telefono: pedido?.billing?.phone,
                                email_contacto: validateEmail(pedido?.billing?.email) !== true ? '' : pedido?.billing?.email,
                                direccion: `${pedido?.billing?.address_1}, ${pedido?.billing?.city}, ${pedido?.billing?.state}`,
                                observaciones_direccion: pedido?.billing?.address_2,
                                nota: pedido.customer_note,
                                fecha_min_entrega: '',
                                fecha_max_entrega: '',
                                bultos: contar_bultos,
                                order_key: pedido.order_key,
                                meta_data: []
                            }

                            if(pedido.meta_data) if(Array.isArray(pedido.meta_data) !== false) if(pedido.meta_data.length > 0){
                                for( const meta of pedido.meta_data ){
                                    nuevo_registro_confirmafacil.meta_data.push({
                                        key: meta.key,
                                        value: meta.value.toString()
                                    })
                                }
                            }

                            datos_marketplace.push(nuevo_registro_confirmafacil)
                            
                        }
                    }
                }
                    
            return exportarDatos(datos_marketplace)
                
            default:
                break;
        }
    }

    const consultarIntegraciones = async () => {
        const url = `${urlapi}/importador/availables`
        setLoading(true)
        return fetch(url, {
            method: "GET",
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${tokenSession}`
            }
        })
        .then(pros => pros.json())
        .then(pros => {
            if(!pros){
                toast.error('Ocurrió un error inesperado, Sin respuesta del servidor')
                return setLoading(false)
            } else if(pros.errorMessage){
                toast.error(pros.errorMessage)
                return setLoading(false)
            } else if(Array.isArray(pros) !== false){
                setIntegraciones(pros)
            }
            setLoading(false)
        })
        .catch(error => {
            toast.error('Ocurrió un error inesperado')
            setLoading(false)
        })
    }

    const mostrarWizardDatosImportadosConfirmafacil = (formulario) => {
        return <div className='mt-3'>
            <h4>Hemos encontrado {datosImportados.payload.length} Ordenes desde la fecha {formulario.fecha_desde} hasta la fecha {formulario.fecha_hasta}</h4>
            { typeof datosImportados?.paginas !== "undefined" ? <div className='d-block mb-3'><Badge variant='dark' style={{ textTransform: "uppercase" }}>{datosImportados?.paginas} páginas</Badge></div> : false }
            <button className='btn btn-outline-success mb-3 mr-3' onClick={()=>importarTodo()}>IMPORTAR TODO</button>
            <button className='btn btn-outline-success mb-3 mr-3' onClick={()=>importarSeleccion()}>IMPORTAR SELECCIÓN</button>
            <div className='table-responsive'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Fecha Despacho</th>
                            <th>Transporte</th>
                            {/* <th>Grupos</th> */}
                            <th>Órdenes</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        datosImportados.payload.map((ruta,iruta) => {
                            let grupos = []
            
                            return <tr key={ruta.id}>
                                <th>{ruta.numero.toString()}</th>
                                <th>{ruta.dataEmissao}</th>
                                <th>{typeof ruta.transportadora === 'object' ? ruta.transportadora.nome : 'Sin información' }</th>
                                {/* <th>{grupos.join(', ')}</th> */}
                                <th>{Array.isArray(ruta.trecho) !== false ? ruta.trecho.length : 'Sin información' }</th>
                                <th>
                                    <button className='btn btn-outline' onClick={()=>marcarRutaImportar(iruta)}>
                                        { ruta.seleccionada === true ? <i className="fas text-success fa-check-circle"></i> : false } IMPORTAR
                                    </button>
                                    </th>
                            </tr>
                        })
                    }
                    </tbody>
                </table>
            </div>
        </div>
    }

    const mostrarWizardDatosImportados = (formulario) => {
        return <div className='mt-3'>
            <h4>Hemos encontrado {datosImportados.payload.length} Rutas para {formulario.transporte} el {formulario.fecha}</h4>
            <button className='btn btn-outline-success mb-3 mr-3' onClick={()=>importarTodo()}>IMPORTAR TODO</button>
            <button className='btn btn-outline-success mb-3 mr-3' onClick={()=>importarSeleccion()}>IMPORTAR SELECCIÓN</button>
            <div className='table-responsive'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Fecha Despacho</th>
                            <th>Transporte</th>
                            <th>Grupos</th>
                            <th>Órdenes</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        datosImportados.payload.map((ruta,iruta) => {
                            let grupos = []
                            if(Array.isArray(ruta.dispatches) !== false){
                                ruta.dispatches.map(despacho => {
                                    let grupo = false
                                    const i_tag = despacho.tags.findIndex(ta => {
                                        const llaves = Object.keys(ta)
                                        if(llaves.filter(lla => lla === 'GRUPO').length > 0) return true
                                        return false
                                    })
                                    if(i_tag > -1) grupo = despacho.tags[i_tag].GRUPO
                                    if(grupo){
                                        if(!grupos.includes(grupo)) grupos.push(grupo)
                                    }
                                })
                            }
                            return <tr key={ruta.id}>
                                <th>{ruta.id}</th>
                                <th>{ruta.dispatch_date}</th>
                                <th>{typeof ruta.truck === 'object' ? ruta.truck.identifier : 'Sin información' }</th>
                                <th>{grupos.join(', ')}</th>
                                <th>{Array.isArray(ruta.dispatches) !== false ? ruta.dispatches.length : 'Sin información' }</th>
                                <th>
                                    <button className='btn btn-outline' onClick={()=>marcarRutaImportar(iruta)}>
                                        { ruta.seleccionada === true ? <i className="fas text-success fa-check-circle"></i> : false } IMPORTAR
                                    </button>
                                    </th>
                            </tr>
                        })
                    }
                    </tbody>
                </table>
            </div>
        </div>
    }

    const mostrarWizardDatosImportadosMarketPlace = (formulario) => {
        return <div className='mt-3'>
            <h4>Hemos encontrado {datosImportados.payload.length} Rutas para {formulario.fecha}</h4>
            <button className='btn btn-outline-success mb-3 mr-3' onClick={()=>importarTodo()}>IMPORTAR TODO</button>
            <button className='btn btn-outline-success mb-3 mr-3' onClick={()=>importarSeleccion()}>IMPORTAR SELECCIÓN</button>
            <div className='table-responsive'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Fecha Despacho</th>
                            <th>Órdenes</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        datosImportados.payload.map((ruta,iruta) => {
                            let grupos = []
                            
                            return <tr key={ruta.id}>
                                <th>{ruta.id}</th>
                                <th>{ruta.fecha_entrega}</th>
                                <th>{Array.isArray(ruta.ordenes) !== false ? ruta.ordenes.length : 'Sin información' }</th>
                                <th>
                                    <button className='btn btn-outline' onClick={()=>marcarRutaImportar(iruta)}>
                                        { ruta.seleccionada === true ? <i className="fas text-success fa-check-circle"></i> : false } IMPORTAR
                                    </button>
                                </th>
                            </tr>
                        })
                    }
                    </tbody>
                </table>
            </div>
        </div>
    }

    const mostrarDatosImportados = () => {
        if(!datosImportados) return false
        const i = integraciones.findIndex(integ => integ._id === integracionActiva)
        const seleccionada = integraciones[i]
        switch (seleccionada.descripcion) {
            case 'Beetrack':
                return mostrarWizardDatosImportados(seleccionada.formulario)
            case CODIGOS_INTEGRACIONES.CONFIRMA_FACIL:
                return mostrarWizardDatosImportadosConfirmafacil(seleccionada.formulario)
            case CODIGOS_INTEGRACIONES.MARKETPLACE_TREMUS:
                return mostrarWizardDatosImportadosMarketPlace(seleccionada.formulario)
            default:
                break;
        }
    }

    const mostrarFormularioOpciones = () => {
        if(loading===true) return <Spinner animation='border' />
        if(loadingConfig===true) return <div>
            <Spinner animation='border' />
            <h6 className='mb-0'>Cargando los datos de la integración</h6>
        </div>
        if(!integracionActiva) return <div>
            <p className='mb-2'>Selecciona una de las siguientes integraciones activas para importar pedidos</p>
            <div className='row'>
            {
                integraciones.map(integracion => {
                    return <div key={integracion._id} className="col-md-3" >
                        <div className='card p-3 mt-3 hover' onClick={()=>seleccionarIntegracion(integracion._id)}>
                            <h4 className='mb-0'>{integracion.descripcion}</h4>
                            <p className='mb-0'>Versión: {integracion.nombre}</p>
                        </div>
                    </div>
                })
            }
            </div>
        </div>

        const i = integraciones.findIndex(integ => integ._id === integracionActiva)
        const seleccionada = integraciones[i]
        
        if(seleccionada.descripcion === 'Beetrack') return <div>
            <h4 className='mb-0'>Importador Beetrack</h4>
            <p>Consulta la información utilizando los filtros</p>
            <div className='row'>
                <div className='col-md-3'>
                    <label className='form-control-label d-block'>Fecha de consulta</label>
                    <input className='form-control' type="date" name="fecha" onChange={handleChangeConfig} />
                </div>
                <div className='col-md-3'>
                    <label className='form-control-label d-block'>Selecciona un vehículo de tu flota</label>
                    <select className='form-control' name="transporte" onChange={handleChangeConfig}>
                        <option value="">Selecciona</option>
                        {
                            configIntegracionActiva.filter(integr => integr.sub_tipo === 'beetrack_api_nombre_transporte').map(inte => {
                                return <option value={inte.valor}>{inte.valor}</option>
                            })
                        }
                    </select>
                </div>
                <div className='col-md-3'>
                    <label className='form-control-label d-block'>Click para consultar</label>
                    {
                        loadingDatos===true ? <div className="loadingboxsmall">
                            <Spinner animation='border' />
                            <h3>Cargando información...</h3>
                        </div> : <button className='btn btn-success' onClick={()=>importarDatos() } >CONSULTAR RUTAS</button>
                    }
                </div>
                <div className='col-md-12'>
                   
                   
                    {mostrarDatosImportados()}
                </div>
            </div>
        </div>

        if(seleccionada.descripcion === CODIGOS_INTEGRACIONES.CONFIRMA_FACIL) return <div>
            <h4 className='mb-0'>Importador Confirmafacil</h4>
            <p>Consulta la información utilizando los filtros</p>
            <div className='row'>
                <Col md={3}>
                    <label className='form-control-label d-block'>Fecha a consultar desde:</label>
                    <input className='form-control' type="date" name="fecha_desde" onChange={handleChangeConfig} />
                </Col>
                <Col md={3}>
                    <label className='form-control-label d-block'>Fecha a consultar hasta:</label>
                    <input className='form-control' type="date" name="fecha_hasta" onChange={handleChangeConfig} />
                </Col>
                <Col md={2}>
                    <label className='form-control-label d-block'>Página</label>
                    <input className='form-control' type="number" name="pagina" onChange={handleChangeConfig} />
                </Col>
                <div className='col-md-3'>
                    <label className='form-control-label d-block'>Click para consultar</label>
                    {
                        loadingDatos===true ? <div className="loadingboxsmall">
                            <Spinner animation='border' />
                            <h3>Cargando información...</h3>
                        </div> : <button className='btn btn-success' onClick={()=>importarDatos() } >CONSULTAR ORDENES</button>
                    }
                </div>
                <div className='col-md-12'>
                    {mostrarDatosImportados()}
                </div>
            </div>
        </div>
        
        if(seleccionada.descripcion === CODIGOS_INTEGRACIONES.MARKETPLACE_TREMUS) return <div>
            <h4 className='mb-0'>Importador Marketplace Tremus</h4>
            <p>Consulta la información utilizando los filtros</p>
            <div className='row'>
                <Col md={3}>
                    <label className='form-control-label d-block'>Fecha a consultar:</label>
                    <input className='form-control' type="date" name="fecha" onChange={handleChangeConfig} />
                </Col>
                {/* 
                <Col md={3}>
                    <label className='form-control-label d-block'>Fecha a consultar hasta:</label>
                    <input className='form-control' type="date" name="fecha_hasta" onChange={handleChangeConfig} />
                </Col>
                <Col md={2}>
                    <label className='form-control-label d-block'>Página</label>
                    <input className='form-control' type="number" name="pagina" onChange={handleChangeConfig} />
                </Col> */}
                <div className='col-md-3'>
                    <label className='form-control-label d-block'>Click para consultar</label>
                    {
                        loadingDatos===true ? <div className="loadingboxsmall">
                            <Spinner animation='border' />
                            <h3>Cargando información...</h3>
                        </div> : <button className='btn btn-success' onClick={()=>importarDatos() } >CONSULTAR ORDENES</button>
                    }
                </div>
                <div className='col-md-12'>
                    {mostrarDatosImportados()}
                </div>
            </div>
        </div>
    }

    return <div className='mt-3'>
        {mostrarFormularioOpciones()}
    </div>
}

export default ImportadorIntegraciones