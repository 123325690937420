import { PiWarningCircleBold } from "react-icons/pi"
import { formatoMoneda } from "../../../lib/helpers/main"
import { Card, Col, Modal, Row, Tab, Table, Tabs } from "react-bootstrap"
import { Bar } from "react-chartjs-2"
import { useState } from "react"
import { CiMoneyCheck1 } from "react-icons/ci"
import { maps_key, tipos_direccion } from "../../../lib/backend/data"
import ReactGoogleAutocomplete from "react-google-autocomplete"
import { default_proveedores, encontrarLogoPorCodigoProveedor, encontrarMenorTotal } from "../../proveedores_transporte/data"
import { FaRegClock } from "react-icons/fa"

const TablaCotizacion = (props) => {
    const {
        options_graph,
        data_graph,
        otros_registros,
        registros,
        courriers
    } = props
    const [ show, setShow ] = useState(false)

    

    courriers.map((cou,i) => {
        const filtrado = registros.filter(re => {
            if(!re.tarifa) return false
            if(!Array.isArray(re.tarifa)) return false
            return re.tarifa.filter(ta => ta.provider === cou.name)
        })

        const calcular_total = filtrado.reduce((prev,next) => {
            let sumar = 0
            const encontrar = next.tarifa.findIndex(ta => ta.provider === cou.name)
            if(encontrar > -1 ){
                const tarifas_encontradas = next.tarifa[encontrar].tarifas
                if(tarifas_encontradas.length > 0){
                    if(tarifas_encontradas[0].price) sumar = tarifas_encontradas[0].price
                }
            }
            return prev + sumar
        }, 0)
        courriers[i].total = calcular_total
    })

    const mas_economico = encontrarMenorTotal(courriers)

    return <div>
        <Modal show={show} onHide={() => setShow(false)} centered size="lg">
            <div className="p-3">
        <div className='mb-3'><Bar options={options_graph} data={data_graph} /></div>
            <Tabs
                defaultActiveKey="courrier-1"
                className="mb-3"
        >
    
        {
            courriers.map((cou,ic) => {

                const filtrado = registros.filter(re => {
                    if(!re.tarifa) return false
                    if(!Array.isArray(re.tarifa)) return false
                    return re.tarifa.filter(ta => ta.provider === cou.name)
                })

                const logo = encontrarLogoPorCodigoProveedor(cou.name, default_proveedores )

                const componente_titulo = <div>
                    { logo ? <img style={{ height: 20 }} src={logo} /> : <h6 className="mb-0">{`${cou.name ? cou.name.toUpperCase() : ''}`}</h6>}
                    {cou.name === mas_economico ? <span className="mt-1 d-block text-center" style={{ fontWeight: "bold", color: "white", background: "#28a745", fontSize: 9 }}>MÁS ECONÓMICO</span> : false }
                </div>

                return <Tab eventKey={`courrier-${(ic+1)}`} title={componente_titulo}>
                    <h6>{filtrado.length} Registros </h6>
                    <h4>${formatoMoneda(cou.total)}</h4>
                    <Table>
                        <thead>
                            <tr>
                                <th>REFERENCIA</th>
                                <th>COURRIER</th>
                                <th>DIRECCION</th>
                                <th>TRÁNSITO</th>
                                <th>TARIFA</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                filtrado.map((reg,ire) => {
                                    let tarifa = false
                                    let detalles_tarifa = false
                                    if(reg.tarifa) if(Array.isArray(reg.tarifa) !== false){
                                        const i = reg.tarifa.findIndex(ta => ta.provider === cou.name)
                                        if(i > -1 ){
                                            const precios = reg.tarifa[i].tarifas
                                            if(Array.isArray(precios) !== false) if(precios.length > 0) if(precios[0].price) if(!isNaN(parseFloat(precios[0].price))) {
                                                tarifa = precios[0].price
                                                detalles_tarifa = precios[0]
                                            }
                                        }
                                    }
                                    return <tr key={`tabla-${ire}`}>
                                    <th>{reg.referencia}</th>
                                    <th style={{ textTransform: "uppercase" }}>{cou.name}</th>
                                    <th>
                                        <Row>
                                            <Col xs={12}>
                                            <label className="form-control-label d-block">Dirección</label>
                                            <ReactGoogleAutocomplete
                                                className="form-control"
                                                defaultValue={reg.direccion}
                                                options={{
                                                    types: tipos_direccion,
                                                    // cokmponentRestrictions: { country: pais },
                                                }}                    
                                                apiKey={maps_key}
                                                onPlaceSelected={(informacion) => {
                                                    console.log({ informacion })
                                                    if(!informacion) return false
                                                    const lat = informacion.geometry.location.lat()
                                                    const lng = informacion.geometry.location.lng()

                                                    let pais = ''
                                                    let codigo_pais = ''
                                                    let level1 = ''
                                                    let level2 = ''
                                                    let level3 = ''

                                                    let formatted_address = ''
                                    
                                                    if(informacion.formatted_address){
                                                        formatted_address = informacion.formatted_address
                                                    }
                                    
                                                    const i_pais = informacion.address_components.findIndex(a => a.types.includes('country'))
                                                    if(i_pais > -1){
                                                        pais = informacion.address_components[i_pais].long_name
                                                        codigo_pais = informacion.address_components[i_pais].short_name
                                                    }
                                    
                                                    const l1 = informacion.address_components.findIndex(a => a.types.includes('administrative_area_level_1'))
                                                    if(l1 > -1) level1 = informacion.address_components[l1].long_name
                                                    
                                                    const l2 = informacion.address_components.findIndex(a => a.types.includes('administrative_area_level_2'))
                                                    if(l2 > -1) level2 = informacion.address_components[l2].long_name
                                                    
                                                    const l3 = informacion.address_components.findIndex(a => a.types.includes('administrative_area_level_3'))
                                                    if(l3 > -1) level3 = informacion.address_components[l3].long_name

                                                    
                                                    let location = {
                                                        type: "Point",
                                                        coordinates: [
                                                            lng, lat
                                                        ]
                                                    }

                                                    const geo_datos = {
                                                        pais,
                                                        codigo_pais,
                                                        formatted_address,
                                                        level1,
                                                        level2,
                                                        level3,
                                                        lat,
                                                        lng
                                                    }
                                                    
                                                    let actual = {...reg}
                                                    actual.direccion = informacion.formatted_address
                                                    actual.geo_datos = geo_datos
                                                    actual.original_geo_datos = informacion
                                                    actual.location = location
                                                    console.log(actual)
                                                    if(props.onChange) props.onChange(actual)                     
                                                }}
                                                />
                                            </Col>
                                            <Col xs={12}>
                                            <label className="form-control-label d-block">Observaciones de la dirección</label>
                                                <input className="form-control" defaultValue={reg.observaciones_direccion} name="observaciones_direccion" onChange={(e) => {
                                                    const { name, value } = e.target
                                                    let actual = {...reg}
                                                    actual[name] = value
                                                    if(props.onChange) props.onChange(actual)
                                                }}  />
                                            </Col>
                                        </Row>                                    </th>
                                    <th>{ !isNaN( parseInt(detalles_tarifa?.tiempo_dias)) ? <p style={{ fontSize: 13 }} className="d-block text-center" ><FaRegClock /> {detalles_tarifa.tiempo_dias} días</p> : <p style={{ fontSize: 13 }} className="d-block text-center" ><FaRegClock /> { detalles_tarifa.transit_time ? detalles_tarifa.transit_time : "No disponible" } </p> }</th>
                                    <th>{tarifa !== false ? formatoMoneda(tarifa) : <span className="text-danger"><PiWarningCircleBold size={20}  /></span> }</th>
                                </tr>
                                })
                            }
                        </tbody>
                    </Table>
                </Tab>
            })
        }
        <Tab eventKey={`courrier-otros`} title={`SIN TARIFA`}>
            <h6>{otros_registros.length} Registros con tarifas encontradas</h6>
            {
                                otros_registros.map((reg,ire) => {
                                    
                                    return <tr key={`tabla-${ire}`}>
                                    <th>{reg.referencia}</th>
                                    <th>
                                        <Row>
                                            <Col xs={12}>
                                            <label className="form-control-label d-block">Dirección</label>
                                            <ReactGoogleAutocomplete
                                                className="form-control"
                                                defaultValue={reg.direccion}
                                                options={{
                                                    types: tipos_direccion,
                                                    // cokmponentRestrictions: { country: pais },
                                                }}                    
                                                apiKey={maps_key}
                                                onPlaceSelected={(informacion) => {
                                                    console.log({ informacion })
                                                    if(!informacion) return false
                                                    const lat = informacion.geometry.location.lat()
                                                    const lng = informacion.geometry.location.lng()

                                                    let pais = ''
                                                    let codigo_pais = ''
                                                    let level1 = ''
                                                    let level2 = ''
                                                    let level3 = ''

                                                    let formatted_address = ''
                                    
                                                    if(informacion.formatted_address){
                                                        formatted_address = informacion.formatted_address
                                                    }
                                    
                                                    const i_pais = informacion.address_components.findIndex(a => a.types.includes('country'))
                                                    if(i_pais > -1){
                                                        pais = informacion.address_components[i_pais].long_name
                                                        codigo_pais = informacion.address_components[i_pais].short_name
                                                    }
                                    
                                                    const l1 = informacion.address_components.findIndex(a => a.types.includes('administrative_area_level_1'))
                                                    if(l1 > -1) level1 = informacion.address_components[l1].long_name
                                                    
                                                    const l2 = informacion.address_components.findIndex(a => a.types.includes('administrative_area_level_2'))
                                                    if(l2 > -1) level2 = informacion.address_components[l2].long_name
                                                    
                                                    const l3 = informacion.address_components.findIndex(a => a.types.includes('administrative_area_level_3'))
                                                    if(l3 > -1) level3 = informacion.address_components[l3].long_name

                                                    
                                                    let location = {
                                                        type: "Point",
                                                        coordinates: [
                                                            lng, lat
                                                        ]
                                                    }

                                                    const geo_datos = {
                                                        pais,
                                                        codigo_pais,
                                                        formatted_address,
                                                        level1,
                                                        level2,
                                                        level3,
                                                        lat,
                                                        lng
                                                    }
                                                    
                                                    let actual = {...reg}
                                                    actual.direccion = informacion.formatted_address
                                                    actual.geo_datos = geo_datos
                                                    actual.original_geo_datos = informacion
                                                    actual.location = location
                                                    console.log(actual)
                                                    if(props.onChange) props.onChange(actual)                     
                                                }}
                                                />
                                            </Col>
                                            <Col xs={12}>
                                            <label className="form-control-label d-block">Observaciones de la dirección</label>
                                                <input className="form-control" defaultValue={reg.observaciones_direccion} name="observaciones_direccion" onChange={(e) => {
                                                    const { name, value } = e.target
                                                    let actual = {...reg}
                                                    actual[name] = value
                                                    if(props.onChange) props.onChange(actual)
                                                }}  />
                                            </Col>
                                        </Row>
                                    </th>
                                    <th> <span className="text-danger"><PiWarningCircleBold size={20}  /></span></th>
                                </tr>
                                })
                            }
                </Tab>
         </Tabs>
            </div>
         </Modal> 
         <Card className="p-2 text-center hover text-secondary" border="secondary" onClick={() => setShow(true)} ><h6 className="p-0 m-0 uppercase"><CiMoneyCheck1 size={25} /> <b>VER DETALLES DE LA COTIZACIÓN</b></h6></Card>
    </div>
}

export default TablaCotizacion