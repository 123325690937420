import 'moment/locale/es';
import { Accordion, Alert, Badge, Button, Card, Col, Dropdown, Form, Modal, Nav, Navbar, Row, Spinner, Tab, Table, Tabs } from 'react-bootstrap'
import { useState, useEffect } from "react";
import { getDireccion, getNombreDestinatario } from "../../lib/helpers/pedidos/pedidos";
import BoxList from "../ordenes/box-list";
import { fechaATexto, fechaATextoSimple } from "../../lib/helpers/helpers";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { iconSizeMedium, urlapi } from "../../lib/backend/data";
import Etiquetas from "../etiquetas";
import { colorByStatusAsignacionRuta, detectarPedidosSinGeoDatos, obtenerZonasDePedidos, stringByStatusAsignacionRuta } from "../../lib/helpers/rutas";
import BoxEditarGeoDatosOrden from "../ordenes/box_editar_geodata";
import EmitirEstadoModalMasivoRuta from "../estados_carga/emitir_modal_masivo_ruta";
import ContadorEstadoV2 from "../ordenes/contador_estados_v2";
import { obtenerFechaHoraZonaHorariaLocal } from "../../lib/helpers/dates";
import MapaRutaAsignacion from "../../components/Mapas/mapa_ruta_preview";
import { GrCalendar, GrCircleAlert } from 'react-icons/gr'
import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../redux/actions/session';
import EtiquetaCourrier from '../ordenes/courriers/etiqueta_courriers';
import { FaCheckCircle, FaFileInvoice, FaMap, FaPlus, FaRegFileExcel, FaRegMap } from 'react-icons/fa';
import { AiOutlineBarcode } from 'react-icons/ai';
import { rutas } from '../../lib/routes/routes';
import ImprimirManifest from './imprimirManifest';
import ContadorEstadoRuta from './conteo_estados_ruta';
import { MdRoute } from 'react-icons/md';
import GraficoEstadisticaOrdenes from '../estadisticas/grafico_estadistica_ordenes';
import GeneradorEtiquetasPersonalizado from '../ordenes/etiquetas/generador_etiquetas';
import { IoMdShare } from 'react-icons/io';
import { FiBox } from 'react-icons/fi';
import AgendarRecoleccionModal from '../recolecciones/crear_modal';

const DetailFull = (props) => {
    let pedido = false
    const estados = props.estados ? props.estados : []
    const [ loadingMaster, setLoadingMaster ] = useState(false)
    const [ loadingEtiquetas, setLoadingEtiquetas ] = useState(false)
    const [ filtradoEmpresa, setFiltradoEmpresa ] = useState('')
    const [ etiquetas, setEtiquetas ] = useState([])
    const [ resize, setResize ] = useState(0)
    const [ loadingStatus, setLoadingStatus ] = useState(false)
    const [ modalShow, setModalShow ] = useState(false) //Modal de Etiquetas
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [viewMode, setViewMode] = useState('tabla');

    let ruta = false
    const dispatch = useDispatch()
    let marcadores = []
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession

    
    const handleChangeTab = (e) => {
      const nuevo = resize +1 
      return setResize(nuevo)
    }
    
    if(props.ruta){
      if(props.ruta){
        ruta = props.ruta
      }
    }

    if(ruta.pedidos){
      if(Array.isArray(ruta.pedidos) !== false){
        for( const orden of ruta.pedidos ){
          if(orden.location){
              if(Array.isArray(orden.location.coordinates)){
                  if(orden.location.coordinates.length > 0){
                      marcadores.push({
                          lat: orden.location.coordinates[1],
                          lng: orden.location.coordinates[0],
                          ref: orden.pedido,
                          detalles: orden,
                          _id: orden._id,
                          destinatario: getNombreDestinatario(pedido)
                      })
                  }
              }
          }
        }
      }
    }


    const generarEtiquetas = () => {
      setLoadingEtiquetas(true)
      return fetch(`${urlapi}/bodega/etiquetas/multiples-por-idruta`,{
        method:'POST',
        body: JSON.stringify({
            id_ruta: ruta._id
        }),
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${token}`
        }
    })
    .then(res => {
        if(res.status === 401) return dispatch(cerrarSesion())
        return res.json()
    })
    .then(res => {
      console.log(res)
      if(!res){
        toast.error("Sin respuesta")
      } else if(res.errorMessage){
        toast.error(res.errorMessage)
      } else if(Array.isArray(res) !== false){
        setModalShow(true)
        setEtiquetas(res)
      }
      return setLoadingEtiquetas(false)
    })
    .catch(error => {
      toast.error(error.message)
      return setLoadingEtiquetas(false)
    })
    }

    const descargarRuta = async () => {
      setLoadingMaster(true)
      return fetch(`${urlapi}/reporte/ordenes/ruta`,{
          method:'POST',
          body: JSON.stringify({
              id_ruta: ruta._id
          }),
          headers: {
              'Content-Type':'application/json',
              'Authorization': `Bearer: ${token}`
          }
      })
      .then(res => {
          if(res.status === 401) return dispatch(cerrarSesion())
          return res.blob()
      })
      .then(blob => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          const fecha = obtenerFechaHoraZonaHorariaLocal()
          a.download = `ruta-${ruta.id}-${fecha}.xlsx`;
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();    
          a.remove();
          return setLoadingMaster(false)
      })
      .catch(error => {
          toast.error("Error al consultar la información, intente nuevamente")
          return setLoadingMaster(false)
      })
  }
    const descargarRutaProductos = async () => {
      setLoadingMaster(true)
      return fetch(`${urlapi}/reporte/ordenes/ruta-productos`,{
          method:'POST',
          body: JSON.stringify({
              id_ruta: ruta._id
          }),
          headers: {
              'Content-Type':'application/json',
              'Authorization': `Bearer: ${token}`
          }
      })
      .then(res => {
          if(res.status === 401) return dispatch(cerrarSesion())
          return res.blob()
      })
      .then(blob => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          const fecha = obtenerFechaHoraZonaHorariaLocal()
          a.download = `ruta-${ruta.id}-${fecha}.xlsx`;
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();    
          a.remove();
          return setLoadingMaster(false)
      })
      .catch(error => {
          toast.error("Error al consultar la información, intente nuevamente")
          return setLoadingMaster(false)
      })
  }

  const EtiquetasModal = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            { etiquetas.length > 0 ? <div>
                {/* <Button variant="outline-primary" className="mb-3" size="sm" onClick={() => setEtiquetas(prev => [...[], ...[]]) }>CERRAR VISUALIZACIÓN ETIQUETAS</Button> */}
                <Etiquetas etiquetas={etiquetas} token={token}/>
              </div> : false }
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>CERRAR</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  
  const acciones = (asignacion) => {
    if(loadingStatus===true) return <Spinner animation="border" />

    if(["rechazada-cliente"].includes(asignacion.status)) return false
    if(["pendiente-cliente"].includes(asignacion.status)) {
        return <div>
          <Button variant="success" className="w-100 mt-3 shadow-sm" size="sm" onClick={() => performarAccion('aceptada-cliente', asignacion._id)} ><b>APROBAR</b></Button>
          <Button variant="danger" className="w-100 mt-3 shadow-sm" size="sm" onClick={() => performarAccion('rechazada-cliente', asignacion._id)} ><b>ANULAR INVITACIÓN</b></Button>
        </div>
    }
    
    return <div>
      <Button variant="danger" className="w-100 mt-3 shadow-sm" size="sm" onClick={() => performarAccion('rechazada-cliente', asignacion._id)} ><b>ANULAR INVITACIÓN</b></Button>
    </div>
  }

  const alertaValoresDistintos = (asignacion) => {
    if(!asignacion.propuesta_entrega_proveedor) return false
    if(asignacion.propuesta_entrega.entrega_desde !== asignacion.propuesta_entrega_proveedor.entrega_desde || asignacion.propuesta_entrega.entrega_hasta !== asignacion.propuesta_entrega_proveedor.entrega_hasta){
      return <div>
        <Alert variant="warning"><h6 className="mb-0" style={{ fontSize: 15, fontSize:700 }}><GrCircleAlert /> Las fechas cambiaron</h6></Alert>
      </div>
    }
  }

  const mostrarFechaPropuesta = (asignacion_) => {

    const desde = fechaATextoSimple(asignacion_.propuesta_entrega.entrega_desde)
    const hasta = fechaATextoSimple(asignacion_.propuesta_entrega.entrega_hasta)
    if(!asignacion_) return false
    if(!asignacion_.propuesta_entrega_proveedor) return false
    if( typeof asignacion_.propuesta_entrega_proveedor !== "object") return false

    const desde_proveedor = fechaATextoSimple(asignacion_.propuesta_entrega_proveedor ? asignacion_.propuesta_entrega_proveedor.entrega_desde :  asignacion_.propuesta_entrega.entrega_desde)
    const hasta_proveedor = fechaATextoSimple(asignacion_.propuesta_entrega_proveedor ? asignacion_.propuesta_entrega_proveedor.entrega_hasta :  asignacion_.propuesta_entrega.entrega_hasta)

    if( desde_proveedor === desde && hasta_proveedor === hasta ) return false

    return <div>
      <hr className="hr" />
            <p className="mb-0"><b><GrCalendar /> Fecha propuesta por proveedor</b></p>
            <p className="mb-0" style={{ fontSize: 11 }}><b>Desde</b> {desde_proveedor}</p>
            <p className="mb-0" style={{ fontSize: 11 }}><b>Hasta</b> {hasta_proveedor}</p>
    </div>
  }
    
  const mostrarInvitaciones = () => {
    if(!ruta.asignaciones) return false

    return <div className='mb-3'>
      <h6 className="mb-0" style={{fontWeight:700}}>Asignaciones: {ruta.asignaciones.length}</h6>
      <Accordion defaultActiveKey={0}>
      {
        ruta.asignaciones.map((asignacion,ia) => {
          return <div key={asignacion._id}>
          <Card className="text-primary mt-3">
            <Accordion.Toggle as={Card.Header} eventKey={ia.toString()}>
            <p className="mb-0" style={{ fontSize: 10 }}><b>CREADA:</b> {fechaATexto(asignacion.createdAt)}</p>
            <p className="mb-0" style={{ fontSize: 10 }}><b>ACTUALIZADA:</b> {fechaATexto(asignacion.updatedAt)}</p>
            <h6 className='mb-0' style={{ color: colorByStatusAsignacionRuta(asignacion.status), fontWeight:700 }}><i className="fa-solid fa-circle-exclamation text-warning"></i> {stringByStatusAsignacionRuta(asignacion.status)}</h6>
            <div className='d-flex justify-content-end'><i className="fa-solid fa-caret-down"></i></div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={ia.toString()}>
              <Card.Body>
                {alertaValoresDistintos(asignacion)}
            <p className="mb-0" style={{fontWeight:700}}><b><i className="fa-solid fa-calendar-days"></i> Fecha propuesta</b></p>
            <p className="mb-0" style={{ fontSize: 11 }}><b>Desde</b> {fechaATextoSimple(asignacion.propuesta_entrega.entrega_desde)}</p>
            <p className="mb-0" style={{ fontSize: 11 }}><b>Hasta</b> {fechaATextoSimple(asignacion.propuesta_entrega.entrega_hasta)}</p>
            {mostrarFechaPropuesta(asignacion)}
              {acciones(asignacion)}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
            
          </div>
        })
      }
      </Accordion>
      {/* <hr className="hr" />  */}
    </div>
  }

  const performarAccion = (status,idasignacion) => {
    setLoadingStatus(true)
    return fetch(`${urlapi}/rutas/asignaciones`,{
        method:'PUT',
        body: JSON.stringify({
            idasignacion,
            update: { status }
        }),
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${token}`
        }
    })
    .then(res => {
        if(res.status === 401) return dispatch(cerrarSesion())
        return res.json()
    })
    .then(res => {
        if(!res){
            toast.error('Sin datos')
            return setLoadingStatus(false)
        } else if(res.errorMessage){
            toast.error(res.errorMessage)
            return setLoadingStatus(false)
        } else if(res._id){
            toast.success('Operación realizada exitosamente')
            setTimeout(() => {
                window.location.reload()
            }, 500);
        }
        return setLoadingStatus(false)
    })
    .catch(error => {
        toast.error("Error al consultar la información, intente nuevamente")
        return setLoadingStatus(false)
    })
}

const verificarMismaEmpresa = (empresas) => {
  if(!session.data.empresa) return empresas
  return empresas.filter(emp => ![ session.data.empresa.rut ].includes(emp.rut) )
}

const mostrarEmpresas = () => {
  if(!ruta) return false
  if(!ruta.empresas) return false
  if(Array.isArray(ruta.empresas) !== true ) return false
  const empresas = verificarMismaEmpresa(ruta.empresas)
  if(empresas.length < 1) return false

  return <div>
    <h4>Empresas en este manifiesto</h4>
    <Row>
    {
      empresas.map((business,ib) => {
        return <Col md={3} key={`business-${ib}`} className='mb-3' >
          <Card className='p-3 hover' style={{ border: filtradoEmpresa === business.rut ? "2px solid blue" : false }} onClick={() => {
            let valor = business.rut
            if(filtradoEmpresa === business.rut) valor = ''
            return setFiltradoEmpresa(valor)
          }}>
          <h3 className='mb-0' style={{ fontWeight: "bold" }}>{business.razon_social}</h3>
          <p className='mb-0' >{business.rut}</p>
          </Card>
        </Col>
      })
    }
    </Row>
  </div>
}

    const mostrarVehiculo = (driver) => {
      if(!driver) return false
      return <div>
          <Link className="text-white" to={`/vehiculos/edit-${driver._id}`}><h6 className="mb-0" style={{ fontWeight: 'lighter' }}><b>{driver.modelo}</b> {driver.ano}</h6></Link>
          <h6 className="mb-0" style={{ fontWeight:700 }}>PATENTE: <b>{driver.patente ? driver.patente.toUpperCase() : "SIN INFORMACIÓN"}</b></h6>
          {/* <hr className="hr" />  */}
      </div>
    }

    const mostrarInformacionFlujoCourriers = (externos) => {
      let courriers = externos.map(p => p.courrier) 
      return <div>
        <Card className='p-3'>
        <EtiquetaCourrier id_ruta={ruta._id} recoleccion={ruta?.recoleccion} id_pedido={externos.map(pe => pe._id)} />
        </Card>
      </div>
    }

    if(!ruta) return <div className='p-5'>
    <Row className="justify-content-md-center">
        <Col md={6} className="text-center">
          <img alt="pedidos" src="images/pedidos.png" style={{ width: "50%" }} />
        <h4>Selecciona una ruta para visualizarla</h4>
        </Col>
    </Row>
    {/* <hr className="hr" />  */}
    </div>

    const copiarDirecciones = () => {
      const texto = ruta.pedidos.map(p => {
        const direccion = getDireccion(p)
        return direccion ? direccion : false
      })
      const string = texto.join(`\n\n`)
      navigator.clipboard.writeText(string)
      return toast.info("Texto copiado")
    }

    const handleSelectAll = () => {
      const allOrderIds = registros.map(orden => orden._id);
    
      if (selectedOrders.length === allOrderIds.length) {
        setSelectedOrders([]);
      } else {
        setSelectedOrders(allOrderIds);
      }
    };


    const mostrarAcciones = () => {
      if(selectedOrders.length < 1) return false

      return <div style={{ textAlign: 'right' }} className='mb-3'>
            <GeneradorEtiquetasPersonalizado ids_orders={selectedOrders} />
      </div>
    }
    const handleCheckboxChange = (event) => {
      const { id, checked } = event.target;
    
      setSelectedOrders(prevOrders => {
        if (checked) {
          return [...prevOrders, id];
        } else {
          return prevOrders.filter(orderId => orderId !== id);
        }
      });
    };


    let zonas = obtenerZonasDePedidos(ruta.pedidos)
    let invalidData = detectarPedidosSinGeoDatos(ruta.pedidos)

    const registros = filtradoEmpresa ? ruta.pedidos.filter(or => or.propietario === filtradoEmpresa) : ruta.pedidos
    
    const externos = ruta.pedidos.filter(p => p.recoleccion === true)
    
    return <div style={{ height: "90vh", overflowY: "scroll", overflowX: "hidden" }}>
        {/* <Tabs defaultActiveKey="detalles" className='border-0' onSelect={handleChangeTab}>
          <Tab eventKey="detalles" title="Detalles" className=""> */}
          <Card className='p-2 shadow-sm'>
              <Card className="p-3 mb-3 mx-2 shadow-sm">
              <p style={{ fontSize: 11 }} className="mb-0"><b>Creado</b> {fechaATexto(ruta.createdAt)}</p>
              <h3 className="mb-3" style={{fontWeight:900}}>Ruta {ruta.id}</h3>
              <Navbar bg="light" variant="light" className='mb-3'>
              {/* <Navbar.Brand href="#home">Navbar</Navbar.Brand> */}
              <Nav className="mr-auto" style={{fontSize:14}}>
                <Nav.Link ><Link to={`/rutas/${ruta._id}`}><FaFileInvoice /> Editar</Link> </Nav.Link>
                <Nav.Link ><Link to={`/rutas/${ruta._id}?tab=ruteo`}><MdRoute size={18} /> Rutear</Link> </Nav.Link>
                <Nav.Link ><Link to={`/rutas/${ruta._id}?tab=cotizar`}><FiBox size={18} /> Cotizar</Link> </Nav.Link>
                <Nav.Link ><Link to={`/rutas/${ruta._id}?tab=agregar_ordenes`}><FaPlus size={18} /> Agregar órdenes</Link> </Nav.Link>
                <Nav.Link ><Link to={`/rutas/${ruta._id}?tab=compartir`}><IoMdShare size={18} /> Compartir</Link> </Nav.Link>
                <ImprimirManifest ruta={ruta}/>
                <Nav.Link disabled={loadingEtiquetas} onClick={() => generarEtiquetas()}><AiOutlineBarcode /> Generar etiquetas para imprimir</Nav.Link>
                <Nav.Link onClick={() => descargarRuta()}><FaRegFileExcel /> Descargar ruta</Nav.Link>
                <Nav.Link onClick={() => descargarRutaProductos()}><FaRegFileExcel /> Descargar productos</Nav.Link>
              </Nav>
              {/* <Form inline>
                <FormControl type="text" placeholder="Search" className="mr-sm-2" />
                <Button variant="outline-info">Search</Button>
              </Form> */}
            </Navbar>
              <Row>
            <Col md={4}>
                
                <div className='d-flex justify-content-between align-items-center'>
                    
                    {loadingMaster?<Spinner animation="border"/> : false }
                    {loadingEtiquetas?<Spinner animation="border"/> : false}
                    {/* {loadingEtiquetas ? <div className="mb-3"><Spinner animation="border" /><h4 className="mb-0"><i className="fas fa-barcode mb-0"></i> Consultando/generando etiquetas</h4></div> : false} */}
                  {/* <Dropdown>
                    <Dropdown.Toggle  id="dropdown-basic" className='bg-white border-0 py-0 text-start'><i className="fa-solid fa-ellipsis-vertical text-primary"></i></Dropdown.Toggle>
                    <Dropdown.Menu>
                    <Dropdown.Item as={Link} style={{ textDecoration: "none" }} className="no-decoration" to={`/rutas/${ruta._id}`}>Editar</Dropdown.Item>
                    <Dropdown.Item className='text-primary' onClick={() => generarEtiquetas()}>Generar etiquetas para imprimir</Dropdown.Item>
                    <Dropdown.Item className='text-primary' onClick={() => descargarRuta()}>Descargar ruta</Dropdown.Item>
                    <Dropdown.Item className='text-primary' onClick={() => descargarRutaProductos()}>Descargar productos</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}
                  </div>
                <p className="mb-0">{ruta.descripcion}</p>
                <EmitirEstadoModalMasivoRuta onStatusEmited={() => window.location.reload()} buttonStyle="primary" token={token} ruta={ruta} />
                <h6 className="mb-0" style={{fontWeight:700}}>Fecha de asignación</h6>
                <p style={{ fontSize: 11 }} className="mb-0">{fechaATextoSimple(ruta.createdAt)}</p>
                <h6 className="mb-0" style={{fontWeight:700}}>Fecha máxima de entrega</h6>
                <p style={{ fontSize: 11 }} className="mb-0">{fechaATextoSimple(ruta.entrega_hasta)}</p>
                <hr className="hr" />
                {mostrarInvitaciones()}
                {mostrarVehiculo(ruta.vehiculo)}
                </Col>
                  <Col md={8} >
                <div className='mb-3'><AgendarRecoleccionModal id_ruta={ruta._id} id_pedidos={ruta.pedidos.map(pe => pe._id)}  /></div>
                {/* <Card className='p-2 shadow-sm'> */}
                <h5 className="mb-0" style={{fontWeight:900}}>Zonas detectadas</h5>
                <hr className="hr"/>
                { zonas.map((com,iz) => {
                  return <Badge variant='secondary' className='mr-3' style={{ textTransform: "uppercase" }} key={`z-${iz}`}>{com}</Badge>
                 })} 
                { externos.length > 0 ? mostrarInformacionFlujoCourriers(externos) : false }
                </Col>
                </Row>
                <div className='mb-3'><GraficoEstadisticaOrdenes data={ruta.pedidos} /></div>
                <ContadorEstadoRuta id_ruta={ruta._id} token={token} estados={estados} condicion_default={{ _id: { $in: ruta.pedidos.map(pe => pe._id) } }} />
                <div className='mb-3 p-2'>
                <Tabs defaultActiveKey="mapa" className="m-auto" onSelect={handleChangeTab}>
                  <Tab eventKey="mapa" style={{ border: "1px solid #c6c6c6" }} title={<div><h4 className='mb-0' style={{ fontWeight: "bold"}}><FaRegMap style={{ verticalAlign: "middle" }} /> Mapa</h4></div> } >
                      <div className='p-3'>
                        <MapaRutaAsignacion height={300} pinType="uber" typeView="" resize={resize} markers={marcadores} />
                      </div>
                    </Tab>
                  <Tab eventKey="calor" style={{ border: "1px solid #c6c6c6" }} title={<h4 className='mb-0' style={{ fontWeight: "bold" }}><FaMap style={{ verticalAlign: "middle" }} /> Mapa de calor</h4>}>
                      <div className='p-3'>
                        <MapaRutaAsignacion height={300} pinType="uber" typeView="heatmap" resize={resize} markers={marcadores} />
                      </div>
                    </Tab>
                </Tabs>
                </div>
                
              </Card>

                  <Card className='p-3 mb-3 mx-2 shadow-sm'>
                <Col md={12}>
                    {mostrarEmpresas()}
                    {/* <ContadorEstadoV2 token={token} estados={estados} condicion_default={{ _id: { $in: ruta.pedidos.map(pe => pe._id) } }} /> */}
                    
                    <BoxEditarGeoDatosOrden pedidos={invalidData.invalidos} token={token} />
                    <div className='d-flex justify-content-start align-items-center'>
                      <i className="fa-solid fa-eye mb-2 mr-2"></i>
                      <Form.Check 
                        type="switch"
                        id="custom-switch"
                        label="Vista Tabla"
                        checked={viewMode === 'lista'}
                        onChange={() => setViewMode(viewMode === 'lista' ? 'tabla' : 'lista')}
                        style={{fontSize:14, marginBottom: 10, marginTop: 0}}
                      />
                    </div>
                    <div className='d-flex justify-content-between'>

                    <h5 style={{fontWeight:900}}>Órdenes: {registros.length}</h5>
                    <div>
                    {viewMode !== 'lista' && (
                      <Button size="sm" variant="outline-secondary" className="mb-3 mr-2 shadow-sm" onClick={handleSelectAll}><b>SELECCIONAR TODAS LAS ÓRDENES</b></Button>
                    )}                    
                    <Button size="sm" variant="primary" className="mb-3 shadow-sm" onClick={() => copiarDirecciones()}><b>COPIAR DIRECCIONES AL PORTAPAPELES</b></Button>
                    </div>
                    </div>
                    <Row>
                    
                    {viewMode === 'lista' ? (
                      <div>
                        {mostrarAcciones()}
                      <Table striped responsive className='text-primary'>
                        <thead className='text-uppercase'>
                          <tr>
                            <th>
                              <input type="checkbox" onChange={handleSelectAll} />
                            </th>
                            <th>Orden</th>
                            <th>Destinatario</th>
                            <th>Dirección</th>
                            <th>Estado</th>
                          </tr>
                        </thead>
                        <tbody>
                          {registros.map(orden => {

                            let titulo_estado           = ''
                            let titulo_sub_estado       = ''
                            let color_estado            = '#bebebe'
                            let destinatario = ''

                            if(typeof orden.orden === 'object'){
                              if(typeof orden.orden.billing === 'object'){
                                  destinatario = orden.orden.billing.first_name
                              }
                            }                        

                            if(orden.estado_entrega){
                                if(typeof orden.estado_entrega === 'object'){
                                    if(orden.estado_entrega.titulo) titulo_estado = orden.estado_entrega.titulo
                                    if(orden.estado_entrega.color) color_estado = orden.estado_entrega.color
                                }
                            }

                            if(orden.sub_estado_entrega){
                                if(typeof orden.sub_estado_entrega === 'object'){
                                    if(orden.sub_estado_entrega.titulo) titulo_sub_estado = orden.sub_estado_entrega.titulo
                                }
                            }

                            let string_estado = `${titulo_estado} ${titulo_sub_estado}`
                            if(!titulo_estado && !titulo_sub_estado) string_estado = "Sin datos"

                            

                            return <tr key={orden._id}>
                              <td><input type="checkbox" id={orden._id} onChange={handleCheckboxChange} checked={selectedOrders.includes(orden._id)} /></td>
                              <td>{orden.pedido}</td>
                              <td>{destinatario}</td>
                              <td>{getDireccion(orden)}</td>
                              <td style={{ textTransform: "uppercase" }}>
                              <p style={{ fontSize:9, fontWeight:'normal', textTransform: "uppercase", borderRadius:2, backgroundColor: color_estado, padding: 6, color:'white' }} className="mb-2">{string_estado}</p>
                              </td>
                            </tr>
                          })}
                        </tbody>
                      </Table>
                      </div>
                    ) : (
                      <Row>
                        {registros.map(orden => (
                          <Col md={6} key={orden._id} className="mb-3">
                            <BoxList 
                            showGeoData={true} pedido={orden} 
                            seleccionado={selectedOrders.includes(orden._id)} 
                            selected={selectedOrders} 
                            setSelected={setSelectedOrders}/>
                          </Col>
                        ))}
                      </Row>
                    )}

                   </Row>
                </Col>
                </Card>
                {/* </Row> */}
                </Card>
              {/* </Tab>

            <Tab eventKey="mapa" title="Mapa" className="">
              <Card className='p-2 pl-3 shadow-sm'>
              <Row>
              <Col md={12} className='pl-0'>
                
              </Col>
              </Row>
              </Card>
            </Tab>
            </Tabs> */}

            <EtiquetasModal
              show={modalShow}
              onHide={() => setModalShow(false)}
              />
    </div>
}

export default DetailFull