import { useEffect, useRef, useState } from 'react'
import data from '../../../lib/backend/data'
import { toast } from 'react-toastify';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../../redux/actions/session';

const TiposServicioSelectorPersonalizado = (props) => {
    const {
        defaultValue,
        condicion_default,
        isMulti,
        full_payload
    } = props
    let default_value = defaultValue ? defaultValue : ''
    const [ tiposServicio, setTiposServicio ] = useState([])
    const [ options, setOptions ] = useState([])
    const [ loading, setLoading ] = useState(true)
    const pais = useSelector(state => state.pais)
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const dispatch = useDispatch()
    const selectRef = useRef(null)


    const obtenerDatos = async ()=>{
        setLoading(true)
        return fetch(`${data.urlapi}/configuracion/selector`,{
            method:'POST',
            body: JSON.stringify({
                condicion: condicion_default ? condicion_default : {}
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
                setTiposServicio(res)
                const items = res.map(obj => ({ value: obj._id, label: obj.valor }))
                setTimeout(() => {
                    if(defaultValue){
                        if(selectRef.current){
                            if(Array.isArray(defaultValue)){
                                const default_values = items.filter(e => defaultValue.includes(e.value))
                                selectRef.current?.setValue(default_values)
                            } else {
                                const pos = items.findIndex(e => e.value === defaultValue)
                                if(pos > -1){
                                    const value_search = items[pos]
                                    selectRef.current?.setValue(value_search)
                                }
                            }
                        }
                    }
                }, 100);
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }
    
    useEffect(() => {
        obtenerDatos()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = (e) => {
        if(isMulti === true){
            let opciones = []
            if(e){
                e.map(valor => {
                    if(full_payload === true){
                        const i = tiposServicio.findIndex(es => es._id === valor.value)
                        if(i > -1) opciones.push(tiposServicio[i])
                    } else {
                        opciones.push(valor.value)
                    }

                })
            }
            if(props.onChangeValue) props.onChangeValue(opciones)
            return 
        } else {
            let data = e
            if(full_payload === true){
                const i = tiposServicio.findIndex(es => es._id === e.value)
                console.log(tiposServicio)
                if(i > -1) data = tiposServicio[i]
            }
            if(props.onChangeValue) props.onChangeValue(data)
            return e
        }
    }

    
    return <div>
        <Select
            ref={selectRef}
            // value={default_option}
            isMulti={isMulti}
            placeholder="Buscar configuración..."
            options={tiposServicio.map(obj => ({ value: obj._id, label: obj.valor }))}
            isLoading={loading}
            noOptionsMessage={()=>"Sin opciones"}
            loadingMessage={()=>"Cargando información..."}
            onChange={handleChange}
            className="mb-2"
        />
    </div>

}

export default TiposServicioSelectorPersonalizado