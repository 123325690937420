import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap"
import { estilo_last_mile } from "../../lib/global/styles"
import { useState } from "react"
import { FaEye } from "react-icons/fa"

const PreviewEtiqueta = (props) => {
    const {
        estructuraActual,
        components
    } = props
    const gris = '#e5e5e5'
    const [isModalOpen, setIsModalOpen] = useState(false)

    const showModal = () => {
        setIsModalOpen(true);
      };

    const handleCancel = () => {
        setIsModalOpen(false);
    }
    


    const opcionesImagen = (type, campo) => {
        if(type === "modulo"){
            const i = estructuraActual.modulos.findIndex(modulo => modulo.slug === campo)
            if( i > -1 ){
                if(estructuraActual.modulos[i].allowPhoto === true){
                    return <div className="text-center w-100 mb-3">
                        { estructuraActual.modulos[i].imagen ? <img style={{ margin: "0 auto", height: 80 }} src={estructuraActual.modulos[i].imagen} /> : false }
                    </div>
                }
            }
        }
    }

    const mostrarBoton = (type, campo) => {
        if(type === "modulo"){
            let activo = false
            const i = estructuraActual.modulos.findIndex(modulo => modulo.slug === campo)
            let titulo  = ""
            let extra   = ""
            let onlyRead = false
            if(i > -1) {
                titulo      = estructuraActual.modulos[i].nombre
                extra       = estructuraActual.modulos[i].extra
                onlyRead    = estructuraActual.modulos[i].onlyRead === true
                if(estructuraActual.modulos[i].activo === true){
                    activo = true
                }
            }

            if(!activo) return false
            return <div className="text-center">
                    {opcionesImagen(type, campo)}
                <Row>
                    <Col>{components[campo]}</Col>
                    <Col xs={12}>
                        <p>{extra}</p>
                    </Col>
                </Row>
            </div>
        } else if(type === "campos"){
            let activo = false
            const i_ = estructuraActual.campos.findIndex(ca => ca.slug === campo)
            let titulo = ""
            let onlyRead = false
            if(i_ > -1) {
                titulo = estructuraActual.campos[i_].nombre
                onlyRead = estructuraActual.campos[i_].onlyRead === true
                if(estructuraActual.campos[i_].activo === true){
                    activo = true
                }
            }
            return <div>
                <Form.Group key={`${type}-${campo}`} className="mb-3" controlId={`${type}-${campo}`}>
                        <Form.Check type="switch" disabled={onlyRead} checked={activo} label={titulo} />
                </Form.Group>
            </div>
        }
    }

    return <div>
        <Button size="sm" className="mb-3" variant="light" onClick={showModal}><FaEye /> PREVISUALIZAR</Button>

        <Modal show={isModalOpen} open={() => showModal()} onHide={handleCancel}>
        <Modal.Header closeButton>
                <Modal.Title>Previsualizar etiqueta</Modal.Title>
        </Modal.Header>
        <Card >
            <Row className="mt-3">
                <Col xs={12}>
                    <Card style={{ border: "none" }} className="">
                        {mostrarBoton('modulo',"logotipo")}
                    </Card>
                </Col>
                <Col xs={12}>
                    <Card style={{ border: "none", height: "90%" }} className="">
                        {mostrarBoton('modulo',"qr")}
                    </Card>
                </Col>
                <Col xs={12}>
                    <Card style={{ border: "none" }} className="">
                        {mostrarBoton('modulo',"resumen")}
                    </Card>
                </Col>
                <Col xs={12}>
                    <Card style={{ border: "none" }} className="">{mostrarBoton('modulo',"barcode")}</Card>
                </Col>
            </Row>
        </Card>
        </Modal>
        
    </div>
}

export default PreviewEtiqueta