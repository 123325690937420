import Moment from 'react-moment'
import 'moment/locale/es';
import { Button, Card, Col, Container, Dropdown, DropdownButton, Modal, OverlayTrigger, Row, Spinner, Tab, Tabs, Tooltip } from 'react-bootstrap'
import { useEffect, useRef, useState } from "react";
import { getNombreDestinatario } from "../../lib/helpers/pedidos/pedidos";
import BoxConductor from "../conductores/box_conductor";
import TablaProductos from '../ordenes/tabla_productos';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { urlapi } from '../../lib/backend/data';
import { useDispatch, useSelector } from 'react-redux';
import { formatDateHoy } from '../../lib/helpers/opcionesfecha';
import MetaDatosOrden from '../ordenes/metadata/metadatos_orden';
import { cerrarSesion } from '../../redux/actions/session';
import LoadingOrders from '../general/loadingAnimations/loadingOrders';
import 'react-vertical-timeline-component/style.min.css';
import { rutas } from '../../lib/routes/routes';
import { FaAngleLeft, FaPhoneAlt, FaRegStickyNote } from 'react-icons/fa';
import EtiquetaCourrier from '../ordenes/courriers/etiqueta_courriers';
import { MdEmail } from 'react-icons/md';
import Barcode from 'react-barcode';
import { url_images } from '../../lib/global/data';
import { useReactToPrint } from 'react-to-print';
import { modulos_datos } from './data';
import VisualizadorContenido from './visualizador_contenido';
import { fechaATextoSimple } from '../../lib/helpers/helpers';
import { DateTime } from 'luxon';
import MapaDetallePedido from '../../components/Mapas/mapa_detalle_pedido';

const DetallesDocumento = (props) => {
    const {
      id,
      esquema
    } = props
    const [ pedido, setPedido ] = useState(false)
    const [ pedidoEditar, setPedidoEditar ] = useState(false)
    const [ loadingDetalle, setLoadingDetalle ] = useState(true)
    const [ estados, setEstados ] = useState([])
    const [ etiquetas, setEtiquetas ] = useState([])
    const [ marcadores, setMarcadores ] = useState([])
    const [ showModal, setShowModal ] = useState(false)
    const [ lineaTiempo, setLineaTiempo ] = useState([])
    const session = useSelector(state => state.miusuario)
    const dispatch = useDispatch()
    const token = session.tokenSession
    const [ loading, setLoading ] = useState(false)
    const proveedor = esquema

    let logotipo_empresa = ''  
    if(session){
      if(session.data){
        if(session.data.empresa){
          if(session.data.empresa.logotipo) logotipo_empresa = session.data.empresa.logotipo
        }
      }
    }

    //REACT-TO-PRINT
    const Document = useRef();
    const pageStyle = `
  @page {
    size: 62mm;
  }
  @media print {
    .page-break {
      page-break-before: always;
    }
    .main {
      padding:20px;
    }
  }  
`;
    const handlePrint = useReactToPrint({
      content: () => Document.current,
      pageStyle
    });

    console.log("PEDIDO:", pedido)

    // console.log("BODEGA:", bodegaOrigen)

    const obtenerPedido = async () => {
        if(!id) return setLoadingDetalle(false)
        setLoadingDetalle(true)
        return fetch(`${urlapi}/ordenes/details-full?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingDetalle(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingDetalle(false)
            } else if(res.orden){
                if(res.time_line){
                    if(Array.isArray(res.time_line) !== false) setLineaTiempo(res.time_line)
                }
                if(res.orden) {
                    setPedido(res.orden)
                    setPedidoEditar(res.orden)
                    setEtiquetas(res.etiquetas)
                  }
                  let puntos = []
                  if(res.estados) setEstados(res.estados)
                  if(res.orden){
                    if(res.orden.location){
                      if(Array.isArray(res.orden.location.coordinates)){
                          if(res.orden.location.coordinates.length > 0){
                            puntos.push({
                              lat: res.orden.location.coordinates[1],
                              lng: res.orden.location.coordinates[0],
                              ref: res.orden.pedido,
                              destinatario: getNombreDestinatario(res.orden)
                            })
                            setMarcadores(puntos)
                          }
                      }
                    }
                  }
            }
            return setLoadingDetalle(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingDetalle(false)
        })
    }
    
    useEffect(() => {
      obtenerPedido()
    },[ id ])

    console.log("pedidoEditar:", pedidoEditar)                     

      

      const mostrarInformacion = () => {
        if(loadingDetalle) return false
        if(!pedido) return false

        let defaultAccordion = false

        if(estados.length > 0) defaultAccordion = `estado-${estados.length}`

        let titulo_estado           = ''
        let titulo_sub_estado       = ''
        let color_estado            = '#bebebe'

        if(pedido.estado_entrega){
            if(typeof pedido.estado_entrega === 'object'){
                if(pedido.estado_entrega.titulo) titulo_estado = pedido.estado_entrega.titulo
                if(pedido.estado_entrega.color) color_estado = pedido.estado_entrega.color
            }
        }
    
        if(pedido.sub_estado_entrega){
            if(typeof pedido.sub_estado_entrega === 'object'){
                if(pedido.sub_estado_entrega.titulo) titulo_sub_estado = pedido.sub_estado_entrega.titulo
            }
        }

        let string_estado = `${titulo_estado} ${titulo_sub_estado}`
        if(!titulo_estado && !titulo_sub_estado) string_estado = "Sin datos"


        const logotipoContent = () => {
          return <div className='justify-content-left'>
              <img className='mb-3' alt="logotipo" src={logotipo_empresa? logotipo_empresa : `${url_images}/logoColor.svg`} style={{ width: 200 }} />
          </div>
        }

        const textoContent = () => {
          return <div className="text-left p-0 m-0">
              <p className='mb-3' style={{ fontWeight: 700, fontSize: 10 }}>Transformamos la experiencia en software</p>
          </div>
        }

        const ordenContent = () => {
          return <div className='my-3'>
              <h4 style={{fontWeight:900}}>ORDEN {pedido.pedido}</h4>
          </div>
        }

        const remitenteContent = () => {
          return <div>
          <hr className='mb-0'/>
          <label className='form-control-label' style={{fontWeight:700, fontSize:18}}>Remitente</label>
          <p className='mb-0'><MdEmail /> {pedido.orden.origen?.email ? pedido.orden.origen?.email : "Sin información"}</p>
          <p className='mb-0'><FaPhoneAlt /> {pedido.orden.origen?.phone ? pedido.orden.origen?.phone : "Sin información"}</p>
          </div>
        }

        const destinatarioContent = () => {
          return <div className=''>
          <label className='form-control-label' style={{fontWeight:700, fontSize:18}}>Destinatario</label>
          { pedido.orden.billing.first_name ? <p className='mb-0' style={{ fontWeight: "bold" }}>{pedido.orden.billing.first_name}</p> : false}
          <p className='mb-0'><MdEmail /> {pedido.orden.billing.email}</p>
          <p className='mb-0'><FaPhoneAlt /> {pedido.orden.billing.phone}</p>
          </div>
        }
        
        const origenContent = () => {
          return <div className=''>
          <label className='form-control-label' style={{fontWeight:700, fontSize:18}}>Origen</label>
          { pedido.orden.origen?.first_name ? <p className='mb-0' style={{ fontWeight: "bold" }}>{pedido.orden.origen?.first_name}</p> : false}
          <p className='mb-0'>{pedido.orden.origen?.address_1}</p>
          { pedido.orden.origen?.address_2 ? <p className='mb-0' style={{ fontWeight: "bold" }}><FaRegStickyNote /> {pedido.orden.origen?.address_2}</p> : false}
          </div>
        }
        
        const destinoContent = () => {
          return <div>
          <label className='form-control-label' style={{fontWeight:700, fontSize:18}}>Destino</label>
          <p className='mb-0'>{pedido.orden.billing.address_1}</p>
          { pedido.orden.billing.address_2 ? <p className='mb-0' style={{ fontWeight: "bold" }}><FaRegStickyNote /> {pedido.orden.billing.address_2}</p> : false}
          </div>
        }

        const fechasContent = () => {
          return <div>
          <label className='form-control-label' style={{fontWeight:700, fontSize:18}}>Fechas de compromiso</label>
          <div className='d-flex' style={{gap:'3rem'}}>
          <div>
          <label className='form-control-label mb-0'>Fecha mínima de entrega</label>
          <p className='mb-0'>{formatDateHoy(pedido.fecha_min_entrega)}</p>
          </div>
          <div>
          <label className='form-control-label mb-0'>Fecha tope de entrega</label>
          <p className='mb-0'>{formatDateHoy(pedido.fecha_max_entrega)}</p>
          </div>
          </div>
          </div>
        }

        const productosContent = (pedido) => {
          return <div>
              <label className='form-control-label' style={{fontWeight:700, fontSize:18}}>Productos</label>
              <div md={12} className='d-flex mb-2'>
                  <Col>
                      <label className='form-control-label mb-0'>Peso</label>
                      <p className='mb-0'>{pedido.peso ? pedido.peso : '--'}</p>
                  </Col>
                  <Col>
                      <label className='form-control-label mb-0'>Volumen</label>
                      <p className='mb-0'>{pedido.volumen ? pedido.volumen : '--'}</p>
                  </Col>
                  <Col>
                      <label className='form-control-label mb-0'>Bultos</label>
                      <p className='mb-0'>{pedido.bultos ? pedido.bultos : '--'}</p>
                  </Col>
                  <Col>
                      <label className='form-control-label mb-0'>Bultos refrigerados</label>
                      <p className='mb-0'>{pedido.refrigerado ? pedido.refrigerado : '--'}</p>
                  </Col>
                  <Col>
                      <label className='form-control-label mb-0'>Bultos congelados</label>
                      <p className='mb-0'>{pedido.congelado ? pedido.congelado : '--'}</p>
                  </Col>
              </div>
              <div>
                <TablaProductos viewType="simple" productos={pedido?.productos} id_orden={id} /> 
              </div>
              </div>
        }

        const camposContent = () => {
          return <div>
            <label className='form-control-label' style={{fontWeight:700, fontSize:18}}>Campos adicionales</label>
            <MetaDatosOrden viewType="simple" id_orden={id} />
            </div>
        }

        const courrierContent = () => {
          return <div>
          <hr className='mb-0'/>
          <label className='form-control-label' style={{fontWeight:700, fontSize:18}}>Courrier que gestiona</label>
          <EtiquetaCourrier viewType="simple" id_pedido={id} />
          </div>
        }

        const barCodeContent = () => {
          return <div>
          <Barcode value={pedido._id} />
          </div>
        }

        const conductorContent = () => {
          return <div>
          <hr className='mb-0'/>
          <label className='form-control-label' style={{fontWeight:700, fontSize:18}}>Datos del conductor</label>
          <BoxConductor conductor={pedido.conductor} typeView='enLinea'/>
          </div>
        }

        const mostrarContenidoModulos = (esquema, tipo) => {
          if(!esquema) return false
          if(!Array.isArray(esquema)) return false
          if(esquema.length < 1) return false

          const layout = {
            destino: destinoContent,
            origen: origenContent,
            destinatario: destinatarioContent,
            remitente: remitenteContent,
            campos_personalizados: camposContent,
            conductor: conductorContent,
            notificaciones: () => false,
            fechas: fechasContent,
            mapa: () => <div className='mt-3'><MapaDetallePedido markers={marcadores} /></div>,
            productos: () => productosContent(pedido),
            fecha_creacion: () => <div>{fechaATextoSimple( DateTime.now().toISO() )}</div>,
          }
  
          return <div className="mb-3">
              <h5 style={{ fontWeight: "bold" }}>Contenido de tu documento</h5>
              <Card className="p-2" style={{ minHeight: 700 }}>
              {
                  esquema.map((campo, i) => {
                      const opciones_adicionales = modulos_datos[tipo]
                      const cantidad = campo.name === "1/2" ? 6 : 12
                      return <div key={`esl-${i}`}>
                              <Row>
                              {
                                  Array.from(Array(campo.meta_value).keys()).map(number => {
  
                                      return <Col className="" xs={cantidad}>
                                          <div className="p-2 mb-2" style={{ width: "100%", overflowX: "scroll" }}>
                                              <VisualizadorContenido
                                                  layout={layout}
                                                  onlyView={true}
                                                  posicion={number} 
                                                  content={campo.info} 
                                                  data={opciones_adicionales} 
                                              />
                                          </div>
                                      </Col>
                                  })
                              }
                              </Row>
                      </div>
                  })
              }
              </Card>
          </div>
      }
  
        
        return <div className='text-primary '>
          <Button size='sm' variant='light' onClick={() => setShowModal(true)}><b>PREVISUALIZAR</b></Button>
          <Modal show={showModal} size="lg" onHide={() => setShowModal(false)} centered >
            <Modal.Header closeButton ><h3 className='mb-0'><b>Previsualizar</b></h3></Modal.Header>
            <div ref={Document} className='p-3'>
              {mostrarContenidoModulos(proveedor.esquema, proveedor.module)}
            </div>
          </Modal>

        </div>
      }

    return mostrarInformacion()
}

export default DetallesDocumento