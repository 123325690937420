export const obtenerZonasDePedidos = (ordenes) => {
    if(!ordenes) return []
    if(Array.isArray(ordenes) !== true) return []
    if(ordenes.length < 1) return []
    let zonas = []
    for( const pedido of ordenes ){

        if(!pedido.orden) {
            continue
        }
        if(typeof pedido.orden !== "object") {
            continue
        }
        if(!pedido.orden.geo_datos) {
            continue
        }
        if(typeof pedido.orden.geo_datos !== "object") {
            continue
        }
        if(!pedido.orden.geo_datos.lat) {
            continue
        }
        if(!pedido.orden.geo_datos.level3) {
            continue
        }
        if(!zonas.includes(pedido.orden.geo_datos.level3)) zonas.push(pedido.orden.geo_datos.level3)
    }
    return zonas
}

export const obtenerZonaDeOrden = (pedido) => {
    if(!pedido.orden) {
        return ''
    }
    if(typeof pedido.orden !== "object") {
        return ''
    }
    if(!pedido.orden.geo_datos) {
        return ''
    }
    if(typeof pedido.orden.geo_datos !== "object") {
        return ''
    }
    if(!pedido.orden.geo_datos.lat) {
        return ''
    }
    if(!pedido.orden.geo_datos.level3) {
        return ''
    }
    
    return pedido.orden.geo_datos.level3
}

export const detectarPedidosSinGeoDatos = (pedidos) => {
    const default_data = {
        sinlatlong: 0,
        invalidos: []
    }
    if(!pedidos) return default_data
    if(Array.isArray(pedidos) !== true) return default_data

    let sinlatlong = 0;
    let invalidos = []

    for( const pedido of pedidos ){
        if(!pedido.orden) {
            invalidos.push(pedido)
            sinlatlong++
            continue
        }
        if(typeof pedido.orden !== "object") {
            invalidos.push(pedido)
            sinlatlong++
            continue
        }
        if(!pedido.orden.geo_datos) {
            invalidos.push(pedido)
            sinlatlong++
            continue
        }
        if(typeof pedido.orden.geo_datos !== "object") {
            invalidos.push(pedido)
            sinlatlong++
            continue
        }
        if(!pedido.orden.geo_datos.lat) {
            invalidos.push(pedido)
            sinlatlong++
            continue
        }
    }
    return {
        sinlatlong,
        invalidos
    }
}

const statuses = {
    "pendiente-cliente": {
        title: "Espera por propietario",
        color: "orange"
    },
    "rechazada-cliente": {
        title: "Rechazada por propietario",
        color: "red"
    },
    "pendiente-proveedor": {
        title: "Espera por proveedor",
        color: "orange"
    },
    "rechazada": {
        title: "Rechazada",
        color: "red"
    },
    "aceptada-cliente": {
        title: "Aceptada por propietario",
        color: "#8aca2b"
    },
    "aceptada-proveedor": {
        title: "Aceptada por proveedor",
        color: "#8aca2b"
    }
}

export const stringByStatusAsignacionRuta = (val) => {
    return statuses[val]?.title || val
}

export const colorByStatusAsignacionRuta = (val) => {
    return statuses[val]?.color || "black"
}

export const optionsStatusesAsignacionRuta = () => {
    return Object.keys(statuses).map(key => ({ value: key, label: statuses[key].title }))
}