import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap"
import { estilo_last_mile } from "../../lib/global/styles"
import { useState } from "react"
import { maps_key } from "../../lib/backend/data"
import { FaEye } from "react-icons/fa"

const PreviewEmail = (props) => {
    const {
        estructuraActual,
        components
    } = props
    const gris = '#e5e5e5'
    const [isModalOpen, setIsModalOpen] = useState(false)
    const titulo_estado_component = `<tr><td class="h2-center" style="padding:30px; padding-bottom:0px;color:#000000; font-family:Georgia, serif, 'Merriweather'; font-size:25px; line-height:34px; text-align:center; font-weight:bold">Nuevo estado de carga<div style="font-size:0pt; line-height:0pt;" class="mobile-br-15"></div>
                                              <p class="text" style="color:#000000; font-family:Arial,sans-serif, 'Muli'; font-size:13px !important; line-height:24px; font-weight: 200;">Este es un mensaje que puedes configurar en la sección de procotolos y notificaciones, apartado de configuración</p>
       </td></tr>`
    const mapa_component = `<tr id="mapa" style="padding-top: 10px; color:#000000; font-family:Georgia, serif, 'Merriweather'; font-size:18px; background: white; line-height:34px; text-align:center; font-weight:bold; width: '100%'">
                  <td> <img src="https://maps.googleapis.com/maps/api/staticmap?center=-33.4422073,-70.706841&size=
    10000x200&zoom=15&maptype=roadmap&key=AIzaSyA0Kr5xJJlOqcRToCLukBxhHvpj9nADrII" width="100%" alt="Mapa Ubicacion" /></td></tr>`
    const detalles_evento_component = `<table width="100%" border="0" cellspacing="0" cellpadding="0">
                                        <tr>
                                           <td class="h2-center" style="padding:30px;color:#000000; font-family:Georgia, serif, 'Merriweather'; font-size:17px; line-height:34px; font-weight:bold">
                                              <div style="padding: 10px 20px;border: 1px solid #ebeaea;border-radius: 10px;box-shadow: 0px 9px 15px #dfdfdf;">
                                              <p class="text" style="color:#b5b5b5; font-family:Arial,sans-serif, 'Muli'; font-size:13px; line-height:24px; text-align:left">Viernes 34 de Agosto de 2024</p>
                                              EN TRÁNSITO - INICIO DE RUTA
                                              <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#e3e3e3" class="border" style="font-size:0pt; line-height:0pt; text-align:center; width:100%; min-width:100%"><tr><td bgcolor="#e3e3e3" height="1" class="border" style="font-size:0pt; line-height:0pt; text-align:center; width:100%; min-width:100%">&nbsp;</td></tr></table>
                                              <p class="text" style="color:#000000; font-family:Arial,sans-serif, 'Muli'; font-size:13px; line-height:24px; text-align:left">DETALLES DEL EVENTO</p>
                                              <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#e3e3e3" class="border" style="font-size:0pt; line-height:0pt; text-align:center; width:100%; min-width:100%"><tr><td bgcolor="#e3e3e3" height="1" class="border" style="font-size:0pt; line-height:0pt; text-align:center; width:100%; min-width:100%">&nbsp;</td></tr></table>
                                                <p class="text" style="color:#000000; font-weight: bold; font-family:Arial,sans-serif, 'Muli'; font-size:13px; line-height:24px; text-align:left">Nombre de quien recibe</p>
                                                <p class="text" style="color:#b5b5b5; font-family:Arial,sans-serif, 'Muli'; font-size:13px; line-height:24px; text-align:left">Nelson Gutierrez</p>
                                                <p class="text" style="color:#000000; font-weight: bold; font-family:Arial,sans-serif, 'Muli'; font-size:13px; line-height:24px; text-align:left">Rut de quien recibe</p>
                                                <p class="text" style="color:#b5b5b5; font-family:Arial,sans-serif, 'Muli'; font-size:13px; line-height:24px; text-align:left">25.336.332-3</p>
                                           </div>
                                           </td>
                                        </tr>
                                        <tr>
                                           
                                        </tr>
                                     </table>`
        const info_general_component = `<table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#ffffff">
                               <tr>
                                  <td>
                                     <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                        <tr>
                                           <td class="row30" >
       
                                              <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#e3e3e3" class="border" style=" font-size:0pt; line-height:0pt; text-align:center; width:100%; min-width:100%"><tr><td bgcolor="#e3e3e3" height="1" class="border" style="font-size:0pt; line-height:0pt; text-align:center; width:100%; min-width:100%">&nbsp;</td></tr></table>
                                              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                        <tr>
                                           <td class="h2-center" style="padding:30px; padding-bottom:0px;color:#000000; font-family:Georgia, serif, 'Merriweather'; font-size:25px; line-height:34px; text-align:center; font-weight:bold">
                                              <p class="text" style="color:#000000; font-family:Arial,sans-serif, 'Muli'; font-size:13px; line-height:24px; text-align:left">Referencia</p>
                                              <p class="text" style="color:#000000; font-family:Arial,sans-serif, 'Muli'; font-size:11px; line-height:24px; text-align:left">23523523</p>
                                           </td>
                                        </tr>
                                        <tr>
                                           <td class="h2-center" style="padding:30px; padding-bottom:0px;color:#000000; font-family:Georgia, serif, 'Merriweather'; font-size:25px; line-height:34px; text-align:center; font-weight:bold">
                                              <p class="text" style="color:#000000; font-family:Arial,sans-serif, 'Muli'; font-size:13px; line-height:24px; text-align:left">Origen</p>
                                              <p class="text" style="color:#000000; font-family:Arial,sans-serif, 'Muli'; font-size:11px; line-height:24px; text-align:left">Juncal 100, quilicura, Región metropolitana de Santiago</p>
                                           </td>
                                           <td class="h2-center" style="padding:30px; padding-bottom:0px;color:#000000; font-family:Georgia, serif, 'Merriweather'; font-size:25px; line-height:34px; text-align:center; font-weight:bold">
                                              <p class="text" style="color:#000000; font-family:Arial,sans-serif, 'Muli'; font-size:13px; line-height:24px; text-align:left">Destino</p>
                                              <p class="text" style="color:#000000; font-family:Arial,sans-serif, 'Muli'; font-size:11px; line-height:24px; text-align:left">Santa clara 301, Huechuraba, Región metropolitana de Santiago</p>
                                           </td>
                                        </tr>
                                        <tr>
                                           <td class="h2-center" style="padding:30px; padding-bottom:0px;color:#000000; font-family:Georgia, serif, 'Merriweather'; font-size:25px; line-height:34px; text-align:center; font-weight:bold">
                                              <p class="text" style="color:#000000; font-family:Arial,sans-serif, 'Muli'; font-size:13px; line-height:24px; text-align:left">Transportador</p>
                                              <p class="text" style="color:#000000; font-family:Arial,sans-serif, 'Muli'; font-size:11px; line-height:24px; text-align:left">Razón social de tu empresa</p>
                                              <p class="text" style="color:#000000; font-family:Arial,sans-serif, 'Muli'; font-size:11px; line-height:24px; text-align:left">Dirección fiscal de tu empresa</p>
                                              <p class="text" style="color:#000000; font-family:Arial,sans-serif, 'Muli'; font-size:11px; line-height:24px; text-align:left">Chile</p>
                                           </td>
                                           <td class="h2-center" style="padding:30px; padding-bottom:0px;color:#000000; font-family:Georgia, serif, 'Merriweather'; font-size:25px; line-height:34px; text-align:left; font-weight:bold">
                                                <p class="text" style="color:#000000; font-family:Arial,sans-serif, 'Muli'; font-size:13px; line-height:24px; text-align:left">Datos conductor</p>
                                                <p class="text" style="color:#000000; font-family:Arial,sans-serif, 'Muli'; font-size:11px; line-height:24px; text-align:left">Francisco Gutierrez</p>
                                                <p class="text" style="color:#000000; font-family:Arial,sans-serif, 'Muli'; font-size:11px; line-height:24px; text-align:left">ID Fiscal: 15.353.353-2</p>
                                                <p class="text" style="color:#000000; font-family:Arial,sans-serif, 'Muli'; font-size:11px; line-height:24px; text-align:left">PLACA PATENTE: SPPL34</p>
                                            </td>
                                        </tr>
                                     </table>
                                           </td>
                                        </tr>
                                        <tr>
                                           <td class="separator1" style="padding:30px 30px 0px 30px">
       
                                              <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#e3e3e3" class="border" style="font-size:0pt; line-height:0pt; text-align:center; width:100%; min-width:100%"><tr><td bgcolor="#e3e3e3" height="1" class="border" style="font-size:0pt; line-height:0pt; text-align:center; width:100%; min-width:100%">&nbsp;</td></tr></table>
       </td>
                                        </tr>
                                        <tr>
                                           <td style="padding-bottom: 30px; line-height: 0px; font-size: 0px;"></td>
                                        </tr>
                                     </table>
                                  </td>
                               </tr>
                            </table>`

        let logotipo = ''
        const i = estructuraActual.modulos.findIndex(modulo => modulo.slug === "logo_footer")
            if( i > -1 ){
                if(estructuraActual.modulos[i].allowPhoto === true){
                    if(estructuraActual.modulos[i].imagen) logotipo = estructuraActual.modulos[i].imagen
                }
            }
       const footer_component = `<table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#ffffff">
                               <tr>
                                  <td class="section3" style="padding:0px 30px 30px 30px">
                                     <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                        <tr>
                                           <th class="column-top" style="font-size:0pt; line-height:0pt; padding:0; margin:0; font-weight:normal; vertical-align:top; Margin:0" width="172">
                                              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                 <tr>
                                                    <td class="img" style="font-size:0pt; line-height:0pt; text-align:left"><div class="img-m-center" style="font-size:0pt; line-height:0pt"><a href="#" target="_blank"><img src="${ logotipo ? logotipo : 'https://www.delpa.cl/wp-content/uploads/2022/04/1-02.png'}" border="0"  height="30" alt="" /></a></div></td>
                                                 </tr>
                                              </table>
                                           </th>
                                           <th class="column" style="font-size:0pt; line-height:0pt; padding:0; margin:0; font-weight:normal; Margin:0">
                                              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                 <tr>
                                                    <td align="right">
                                                       <div style="font-size:0pt; line-height:0pt;" class="mobile-br-15"></div>
       
                                                       <table class="center" border="0" cellspacing="0" cellpadding="0">
                                                          <tr>
                                                             <td class="img" style="font-size:0pt; line-height:0pt; text-align:left" width="32"><a href="#" target="_blank"><img src="images/ico_facebook.jpg" border="0" width="16" height="14" alt="" /></a></td>
                                                             <td class="img" style="font-size:0pt; line-height:0pt; text-align:left" width="32"><a href="#" target="_blank"><img src="images/ico_twitter.jpg" border="0" width="16" height="14" alt="" /></a></td>
                                                             <td class="img" style="font-size:0pt; line-height:0pt; text-align:left" width="32"><a href="#" target="_blank"><img src="images/ico_gplus.jpg" border="0" width="20" height="14" alt="" /></a></td>
                                                             <td class="img" style="font-size:0pt; line-height:0pt; text-align:left" width="32"><a href="#" target="_blank"><img src="images/ico_pinterest.jpg" border="0" width="16" height="14" alt="" /></a></td>
                                                             <td class="img" style="font-size:0pt; line-height:0pt; text-align:left" width="16"><a href="#" target="_blank"><img src="images/ico_instagram.jpg" border="0" width="16" height="14" alt="" /></a></td>
                                                          </tr>
                                                       </table>
                                                    </td>
                                                 </tr>
                                              </table>
                                           </th>
                                        </tr>
                                     </table>
                                  </td>
                               </tr>
                            </table>`

       const mostrarBoton = (type, campo, component) => {
        if(type === "modulo"){
            let activo = ''
            const i = estructuraActual.modulos.findIndex(modulo => modulo.slug === campo)
            let titulo  = ""
            let extra   = ""
            let onlyRead = ''
            if(i > -1) {
                titulo      = estructuraActual.modulos[i].nombre
                extra       = estructuraActual.modulos[i].extra
                onlyRead    = estructuraActual.modulos[i].onlyRead === true
                if(estructuraActual.modulos[i].activo === true){
                    activo = true
                }
            }

            if(!activo) return ''
            const items = [
                component
            ]
            if(extra) items.push(`<tr><td class="w-100 text-center"><p class="mt-3">${extra}</p></td></tr>`)
            return items.map(e => e)
        } else if(type === "campos"){
            let activo = false
            const i_ = estructuraActual.campos.findIndex(ca => ca.slug === campo)
            let titulo = ""
            let onlyRead = false
            if(i_ > -1) {
                titulo = estructuraActual.campos[i_].nombre
                onlyRead = estructuraActual.campos[i_].onlyRead === true
                if(estructuraActual.campos[i_].activo === true){
                    activo = true
                }
            }
            return <div>
                <Form.Group key={`${type}-${campo}`} className="mb-3" controlId={`${type}-${campo}`}>
                        <Form.Check type="switch" disabled={onlyRead} checked={activo} label={titulo} />
                </Form.Group>
            </div>
        }
    }

    
    const registrarHTML = () => {
        let email_principal_image       = ''
        let logotipo                    = ''
        let titulo_estado               = mostrarBoton('modulo', 'titulo', titulo_estado_component)
        let mapa_estado                 = mostrarBoton('modulo', 'mapa', mapa_component)
        let detalles_evento             = mostrarBoton('modulo', 'evento', detalles_evento_component)
        let informacion_general         = mostrarBoton('modulo', 'general', info_general_component)
        let footer                      = mostrarBoton('modulo', 'footer', footer_component)


            const i = estructuraActual.modulos.findIndex(modulo => modulo.slug === "logotipo")
            if( i > -1 ){
                if(estructuraActual.modulos[i].allowPhoto === true){
                    logotipo = estructuraActual.modulos[i].imagen ? estructuraActual.modulos[i].imagen : ""
                }
            }
            
            const i_ = estructuraActual.modulos.findIndex(modulo => modulo.slug === "imagen_cabecera")
            if( i_ > -1 ){
                if(estructuraActual.modulos[i_].allowPhoto === true){
                    email_principal_image = estructuraActual.modulos[i_].imagen ? estructuraActual.modulos[i_].imagen : ""
                }
            }
        let texto = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
       <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
       <head>
          <!--[if gte mso 9]>
          <xml>
             <o:OfficeDocumentSettings>
             <o:AllowPNG/>
             <o:PixelsPerInch>96</o:PixelsPerInch>
             </o:OfficeDocumentSettings>
          </xml>
          <![endif]-->
          <meta http-equiv="Content-type" content="text/html; charset=utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
           <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta name="format-detection" content="date=no" />
          <meta name="format-detection" content="address=no" />
          <meta name="format-detection" content="telephone=no" />
          <link href="https://fonts.googleapis.com/css?family=Merriweather:400,400i,700,700i|Muli:400,400i,700,700i" rel="stylesheet" />
       
          <title>Email Template</title>
          
       
          <style type="text/css" media="screen">
             [style*="Muli"] { font-family: 'Muli', Arial, sans-serif !important; }
             [style*="Merriweather"] { font-family: 'Merriweather', Georgia, serif !important; }
       
             /* Linked Styles */
             body { padding:0 !important; margin:0 !important; display:block !important; min-width:100% !important; width:100% !important; background:#f3f3f3; -webkit-text-size-adjust:none }
             a { color:#acacac; text-decoration:none }
             p { padding:0 !important; margin:0 !important } 
             img { -ms-interpolation-mode: bicubic; /* Allow smoother rendering of resized image in Internet Explorer */ }
             .text-top a { color: #000000; }
             #mapa img { width: "100%" }
             /* Mobile styles */
             @media only screen and (max-device-width: 480px), only screen and (max-width: 480px) {
                .mobile-shell { width: 100% !important; min-width: 100% !important; }
                .center { margin: 0 auto !important; }
                .left { margin-right: auto !important; }
       
                .h6-right,
                .text-right,
                .text-date3-right { text-align: left !important; }
                .row30-2 { padding: 30px 15px 30px 15px !important; }
                .pl18 { padding-left: 0px !important; }
                .pr18 { padding-right: 0px !important; }
                .text-footer2,
                .text-footer-r,
                .h6-m-center { text-align: center !important; }
                
                .row5-30,
                .row4-30,
                .separator1 { padding: 0px 0px 0px 0px !important; }
                .separator2 { padding: 30px 0px 0px 0px !important; }
                .bottom-link { padding: 20px 0px 30px 0px !important; }
                .section,
                .bar { padding: 15px 15px 15px 15px !important; }
                .d30 { padding-top: 0px !important; }
                .pb30m { padding-bottom: 0px !important; }
                .pb60m { padding-bottom: 0px !important; }
                .item-img img { width: 90px !important; height: auto !important; }
                
                .row30 { padding: 20px 0px 20px 0px !important; }
                .row2-30 { padding: 20px 0px 20px 0px !important; }
                .header,
                .section30 { padding: 30px 15px 30px 15px !important; }
                .section5 { padding: 0px 0px 30px 0px !important; }
                .section3 { padding: 0px 15px 30px 15px !important; }
                .section6 { padding: 0px 0px 30px 0px !important; }
       
                .td { width: 100% !important; min-width: 100% !important; }
       
                .text-mc,
                .text-top,
                .h2-header,
                .text-top2,
                .text-link-r,
                .h2-m-center,
                .img-m-center,
                .img-m-center2,
                .text-right-mc,
                .text-date-m-center { text-align: center !important; }
       
                .m-auto { height: auto !important; }
                .auto { width: 120px !important; }
       
                .mobile-br-5 { height: 5px !important; }
                .mobile-br-10 { height: 10px !important; }
                .mobile-br-15 { height: 15px !important; }
                .mobile-br-25 { height: 25px !important; }
                .mobile-br-35 { height: 35px !important; }
             
                .m-td,
                .m-td-top,
                .m-td-top-dir,
                .hide-for-mobile { display: none !important; width: 0 !important; height: 0 !important; font-size: 0 !important; line-height: 0 !important; min-height: 0 !important; }
       
                .mobile-block { display: block !important; }
       
                .fluid-img img { width: 100% !important; max-width: 100% !important; height: auto !important; }
       
                .column,
                .column-top,
                .column-dir-top,
                .column-bottom,
                .column-dir { float: left !important; width: 100% !important; display: block !important; }
                .m-td { width: 15px !important; }
                .events li { 
         display: flex;
         font-size: 20px; 
         color: #999;
       }
       
       .events  { 
         position: relative;
         padding: 0 1.5em;  }
       
       .events::after { 
          content: "";
          position: absolute;
          z-index: 2;
          right: 0;
          top: 0;
          transform: translateX(50%);
          border-radius: 50%;
          background: #fff;
          border: 1px #ccc solid;
          width: .8em;
          height: .8em;
       }
       
       
       .events span {
         padding: 0 1.5em 1.5em 1.5em;
         position: relative;
       }
       
       .events span::before {
          content: "";
          position: absolute;
          z-index: 1;
          left: 0;
          height: 100%;
          border-left: 1px #ccc solid;
       }
       
       .events strong {
          display: block;
          font-weight: bolder;
       }
       
       .events { margin: 1em; width: 50%; }
       .events, 
       .events *::before, 
       .events *::after { box-sizing: border-box; font-family: arial; }
             }
          </style>
          
       </head>
       <body class="body" style="padding:0 !important; margin:0 !important; display:block !important; min-width:100% !important; width:100% !important; background:#f3f3f3; -webkit-text-size-adjust:none">
          <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#f3f3f3">
             <tr>
                <td align="center" valign="top">
                   <table width="650" border="0" cellspacing="0" cellpadding="0" class="mobile-shell">
                      <tr>
                         <td class="td" style="width:650px; min-width:650px; font-size:0pt; line-height:0pt; padding:30px 0px 30px 0px; margin:0; font-weight:normal; Margin:0">
                            <!-- Header -->
                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                               <tr>
                                  <td>
                                     <!-- Top Bar -->
                                     <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                        <tr>
                                           <th class="column-top" style="font-size:0pt; line-height:0pt; padding:0; margin:0; font-weight:normal; vertical-align:top; Margin:0">
                                              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                 <tr>
                                                    <td class="text-top" style="color:#000000; font-family:Arial,sans-serif, 'Muli'; font-size:11px; line-height:16px; text-align:left">Nombre de la empresa</td>
                                                 </tr>
                                              </table>
                                           </th>
                                           <th class="column-top" style="font-size:0pt; line-height:0pt; padding:0; margin:0; font-weight:normal; vertical-align:top; Margin:0" width="150">
                                              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                 <tr>
                                                    <td align="right">
                                                       <div style="font-size:0pt; line-height:0pt;" class="mobile-br-15"></div>
       
                                                       <table class="center" border="0" cellspacing="0" cellpadding="0">
                                                          <tr>
                                                             <td class="text-top" style="color:#000000; font-family:Arial,sans-serif, 'Muli'; font-size:11px; line-height:16px; text-align:left" width="17">+</td>
                                                             <td class="text-top2" style="color:#000000; font-family:Georgia, serif, 'Merriweather'; font-size:11px; line-height:16px; text-align:right"><a target="_blank" class="link-black-u" style="color:#000001; text-decoration:underline"><span class="link-black-u" style="color:#000001; text-decoration:underline">VER TRACKING</span></a></td>
                                                          </tr>
                                                       </table>
                                                    </td>
                                                 </tr>
                                              </table>
                                           </th>
                                        </tr>
                                     </table>
                                     <table width="100%" border="0" cellspacing="0" cellpadding="0" class="spacer" style="font-size:0pt; line-height:0pt; text-align:center; width:100%; min-width:100%"><tr><td height="15" class="spacer" style="font-size:0pt; line-height:0pt; text-align:center; width:100%; min-width:100%">&nbsp;</td></tr></table>
       
                                     <!-- END Top Bar -->
                                     <!-- Header -->
                                     <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#ffffff">
                                        <tr>
                                           <td>
                                              <!-- Columns -->
                                              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                 <tr>
                                                    <th class="column-top" style="font-size:0pt; line-height:0pt; padding:0; margin:0; font-weight:normal; vertical-align:top; Margin:0">
                                                       <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                          <tr>
                                                             <td class="content-spacing" width="30"></td>
                                                             <td style="padding-top: 30px;">
                                                                <div class="img" style="font-size:0pt; line-height:0pt; text-align:left"><div class="img-m-center" style="font-size:0pt; line-height:0pt"><a href="#" target="_blank"><img src="${ logotipo ? logotipo : 'https://www.delpa.cl/wp-content/uploads/2022/04/1-02.png'}" border="0" width="201" alt="" /></a></div></div>
                                                                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                                   <tr>
                                                                      <td height="20" class="m-td" style="font-size:0pt; line-height:0pt; text-align:left"></td>
                                                                   </tr>
                                                                   <tr>
                                                                      <td>
                                                                         <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                                            <tr>
                                                                               <td class="h2-header" style="color:#000000; font-family:Georgia, serif, 'Merriweather'; font-size:20px; line-height:18px; text-align:right; font-weight:bold; padding:20px 0px 20px 0px">Tracking de orden: 2535235235235
                                                                                  <p class="text" style="color:#b5b5b5; margin-top: 15px !important; font-family:Arial,sans-serif, 'Muli'; font-size:13px; line-height:10px;">Punto de recogida</p>
                                                                                  <p class="text" style="color:#000000; margin-bottom: 20px !important; font-family:Arial,sans-serif, 'Muli'; font-size:13px; line-height:17px;">Juncal 100, quilicura, región metropolitana</p>
                                                                                  <p class="text" style="color:#b5b5b5; font-family:Arial,sans-serif, 'Muli'; font-size:13px; line-height:14px;">Punto destino</p>
                                                                                  <p class="text" style="color:#000000; font-family:Arial,sans-serif, 'Muli'; font-size:13px; line-height:17px;margin-bottom: 10px !important;">Santiago de Chile, Región metropolitana</p>
                                                                                  <a target="_blank" style="background-image: linear-gradient(#ff1a1f, #ff6c00);padding: 7px 20px;border-radius: 5px;color: white;font-size: 15px;font-family: Arial,sans-serif,'Muli';" >VER TRACKING</a>
                                                                               </td>
                                                                               <td class="m-td" style="font-size:0pt; line-height:0pt; text-align:left" width="20"></td>
                                                                               <td class="m-td" style="font-size:0pt; line-height:0pt; text-align:left" width="1" bgcolor="#000000"></td>
                                                                            </tr>
                                                                         </table>
                                                                      </td>
                                                                   </tr>
                                                                </table>
                                                             </td>
                                                             <td class="content-spacing" width="20"></td>
                                                          </tr>
                                                       </table>
                                                    </th>
                                                    <th class="column-top" style="font-size:0pt; line-height:0pt; padding:0; margin:0; font-weight:normal; vertical-align:top; Margin:0" width="294">
                                                       <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                          <tr>
                                                             <td class="m-td" style="font-size:0pt; line-height:0pt; text-align:left">
                                                                <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#e3e3e3">
                                                                   <tr>
                                                                      <td class="img" style="font-size:0pt; line-height:0pt; text-align:left" width="70" height="30" bgcolor="#ffffff"></td>
                                                                      <td></td>
                                                                   </tr>
                                                                </table>
                                                             </td>
                                                          </tr>
                                                          <tr>
                                                             <td>
                                                                <div class="fluid-img" style="background-image: url(${email_principal_image ? email_principal_image : 'https://tremus.cl/wp-content/uploads/2023/03/camion.jpg' });background-position: right;background-size: cover; width: 294px; height: 388px; font-size:0pt; line-height:0pt; text-align:left"></div>
                                                             </td>
                                                          </tr>
                                                       </table>
                                                    </th>
                                                    <th class="m-td-top" style="font-size:0pt; line-height:0pt; text-align:left; vertical-align:top" width="30" bgcolor="#ffffff">
                                                       <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                          <tr>
                                                             <td class="m-auto" height="328" bgcolor="#e3e3e3"></td>
                                                          </tr>
                                                       </table>
                                                    </th>
                                                 </tr>
                                              </table>
                                              <!-- END Columns -->
                            
                                              <!-- Content -->
                                              
                                              <!-- END content -->
                                           </td>
                                        </tr>
                                     </table>
                                     <!-- END Header -->
                                  </td>
                               </tr>
                            </table>
                            <!-- END Header -->
                            
                            <!-- Section 1 -->
                            <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#ffffff">
                               <tr>
                                  <td class="section" style="padding:0px 0px 0px 0px">
                                     
                                     <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                           ${titulo_estado}
                                     </table>
       
       <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#e3e3e3" class="border" style="margin-top: 20px; font-size:0pt; line-height:0pt; text-align:center; width:100%; min-width:100%"><tr><td bgcolor="#e3e3e3" height="1" class="border" style="font-size:0pt; line-height:0pt; text-align:center; width:100%; min-width:100%">&nbsp;</td></tr></table>
       <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#e3e3e3" class="border" style="margin-top: 20px; font-size:0pt; line-height:0pt; text-align:center; width:100%; min-width:100%">${mapa_estado}</table>
       
                                     ${detalles_evento}
       
                                  
                                  </td>
                               </tr>
                            </table>
                            <!-- END Section 1 -->
       
                            <!-- Section 5 -->
                            ${informacion_general}
                            <!-- END Section 5 -->
       
                            <!-- Footer -->
                            ${footer}
                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                               <tr>
                                  <td style="padding: 30px 15px 30px 15px;line-height: 16px;font-size: 11px;">
                                     <multiline class="text-footer-c" style="color:#000000; font-family:Arial, sans-serif, 'Muli'; font-size:11px; line-height:16px; text-align:center">Todos los derechos reservados, Sistema de última milla desarrollado por DELPA GROUP<span class="mobile-block"></span></multiline>
                                  </td>
                               </tr>
                            </table>
                            <!-- END Footer -->
                         </td>
                      </tr>
                   </table>
                </td>
             </tr>
          </table>
       </body>
       </html>
       `
        return texto
    }
    const html_estado = registrarHTML()
    const showModal = () => {
        setIsModalOpen(true);
      };

    const handleCancel = () => {
        setIsModalOpen(false);
    }
    
    const opcionesImagen = (type, campo) => {
        if(type === "modulo"){
            const i = estructuraActual.modulos.findIndex(modulo => modulo.slug === campo)
            if( i > -1 ){
                if(estructuraActual.modulos[i].allowPhoto === true){
                    return estructuraActual.modulos[i].imagen ? estructuraActual.modulos[i].imagen : ""
                }
            }
        }
    }


    return <div>
        <Button size="sm" className="mb-3" variant="light" onClick={showModal}><FaEye /> PREVISUALIZAR</Button>

        <Modal size="lg" show={isModalOpen} open={() => showModal()} onHide={handleCancel}>
        <Modal.Header closeButton>
                <Modal.Title>Previsualizar etiqueta</Modal.Title>
        </Modal.Header>
        <Card >
        <div dangerouslySetInnerHTML={{ __html: html_estado }} />
            {/* <Row className="mt-3">
                <Col xs={12}>
                    <Card style={{ border: "none" }} className="">
                        {mostrarBoton('modulo',"logotipo")}
                    </Card>
                </Col>
                <Col xs={12}>
                    <Card style={{ border: "none", height: "90%" }} className="">
                        {mostrarBoton('modulo',"qr")}
                    </Card>
                </Col>
                <Col xs={12}>
                    <Card style={{ border: "none" }} className="">
                        {mostrarBoton('modulo',"resumen")}
                    </Card>
                </Col>
                <Col xs={12}>
                    <Card style={{ border: "none" }} className="">{mostrarBoton('modulo',"barcode")}</Card>
                </Col>
            </Row> */}
        </Card>
        </Modal>
        
    </div>
}

export default PreviewEmail