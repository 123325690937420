import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { Accordion, Badge, Button, Card, Col, Form, InputGroup, Modal, OverlayTrigger, Row, Spinner, Tab, Table, Tabs, Tooltip } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import data, { timezone, urlapi } from '../../lib/backend/data'
import { formatDateHoy } from '../../lib/helpers/dates'
import { fechaATexto, fechaATextoSimple } from '../../lib/helpers/helpers'
import { horas, obtenerRangos } from '../../lib/helpers/opcionesfecha'
import { rutas } from '../../lib/routes/routes'
import DetailFull from '../ordenes/detalle-full'
import { es } from 'date-fns/locale'
import { DateRangePicker } from 'react-date-range'
import SelectorRangoFechas from '../general/selector_rango_fechas'
import DetailEstadosCarga from '../estados_carga/detail'
import MapaEstados from '../mapas/mapa_estados'
import SinDatos from '../general/sin_registros'
import MapaEstadosCarga from '../../components/Mapas/mapa_estados'
import { BiMap } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux'
import { cerrarSesion } from '../../redux/actions/session'
import { estilo_last_mile } from '../../lib/global/styles'
import { url_images } from '../../lib/global/data'
import { IoIosArrowBack } from 'react-icons/io'
import { IoPhonePortraitOutline, IoSend } from 'react-icons/io5'
import { FaTruckFront, FaMobile } from 'react-icons/fa6'
import { CiMobile3 } from "react-icons/ci";
import { LuBox } from "react-icons/lu";
import { IoCheckboxOutline } from "react-icons/io5";
import { FaCheckCircle, FaFileAlt, FaUserAlt } from 'react-icons/fa'
import TablaEstadosCarga from '../estados_carga/detail_table'
import { MdDateRange, MdError } from 'react-icons/md'
import TagSinDatos from '../general/tag_sin_datos'
import { FiBox } from 'react-icons/fi'
import { calcularIncidencias } from '../../lib/helpers/estados'
const ActividadConductores = (props) => {
    const initialDate = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    }
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const [ providers, setProviders ] = useState([])
    const [ providerSelected, setProviderSelected ] = useState('')
    const [vehiculos, setVehiculos] = useState([])
    const [loadingVehiculos, setLoadingVehiculos] = useState(true)
    const [showModal, setShowModal] = useState(false)
    const [loadingDetalle, setLoadingDetalle] = useState(false)
    const [pedidoSeleccionado, setPedidoSeleccionado] = useState(false)
    const [idSeleccionado, setIdSeleccionado] = useState(false)
    const [fechaFiltro, setFechaFiltro] = useState(formatDateHoy(new Date()))
    const [selectionRange, setSelectionRange] = useState(initialDate)
    const [ showModalEstados, setShowModalEstados ] = useState(false)
    const [ vehiculoSeleccionado, setVehiculoSeleccionado ] = useState(false)
    const [ estadosSeleccionados, setEstadosSeleccionados ] = useState([])
    const ids = props.id_conductores ? props.id_conductores : []
    const dispatch = useDispatch()

    useEffect(() => {
        obtenerActividadPorVehiculo()
    }, [])

    const obtenerActividadPorVehiculo = async (date_search, rut) => {
        const { desde, hasta } = obtenerRangos(selectionRange.startDate, selectionRange.endDate)
        if (Array.isArray(ids) !== true) return false
        // if(ids.length < 1) return false
        setLoadingVehiculos(true)
        const provider_rut = rut ? rut : providerSelected
        return fetch(`${data.urlapi}/conductores/actividad`, {
            method: 'POST',
            body: JSON.stringify({
                ids,
                fecha: date_search ? date_search : fechaFiltro,
                condicion_fechas: { $gte: desde, $lte: hasta },
                condicion_rutas: {
                    $or: [
                        { entrega_desde: { $gte: desde, $lte: hasta } },
                        { entrega_hasta: { $lte: hasta, $gte: desde } },
                        { entrega_hasta: { $gte: hasta }, entrega_desde: { $lte: desde } },
                    ]
                },
                provider_rut
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
            .then(res => {
                if (res.status === 401) return dispatch(cerrarSesion())
                return res.json()
            })
            .then(res => {
                if (!res) {
                    toast.error('Sin datos')

                } else if (res.errorMessage) {
                    toast.error(res.errorMessage)
                } else if (Array.isArray(res) !== false) {
                    let proveedores = []

                    for( const veh of res ){
                        if(veh.estados){
                            for( const estado of veh.estados ){
                                const provee = estado.proveedor
                                const i = proveedores.findIndex(e => e.rut === veh.propietario)
                                if( i < 0 ) proveedores.push({
                                    rut: veh.propietario,
                                    nombre: provee
                                })
                            }
                        }
                    }
                    
                    setProviders(proveedores)
                    if(proveedores.length > 0){
                        if(providerSelected) if(proveedores.filter(e => e.rut === providerSelected).length < 1) setProviderSelected('')
                    }
                    setVehiculos(res.map(e => {
                        e.seleccionado = true
                        return e
                    }))
                }
                return setLoadingVehiculos(false)
            })
            .catch(error => {
                toast.error(`Error al consultar la información: ${error.message}`)
                return setLoadingVehiculos(false)
            })
    }

    const seleccionarEstado = (e) => {
        setShowModal(true)
        obtenerPedido(e.idrecurso)
    }

    const handleClose = () => {
        setShowModal(false)
    }

    const obtenerPedido = async (id) => {
        setLoadingDetalle(true)
        setIdSeleccionado(id)
        return fetch(`${urlapi}/ordenes/details-full?id=${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
            .then(res => {
                if (res.status === 401) return dispatch(cerrarSesion())
                return res.json()
            })
            .then(res => {
                if (!res) {
                    toast.error('Sin datos')
                    return setLoadingDetalle(false)
                } else if (res.errorMessage) {
                    toast.error(res.errorMessage)
                    return setLoadingDetalle(false)
                } else if (res.orden) {
                    setPedidoSeleccionado(res)
                }
                return setLoadingDetalle(false)
            })
            .catch(error => {
                toast.error(`Error al consultar la información: ${error.message}`)
                return setLoadingDetalle(false)
            })
    }

    const actualizarEstadoListado = (estado_entrega, sub_estado_entrega) => {

    }

    const cambiarRangoFecha = (item) => {
        return setSelectionRange(item.selection)
    }

    const handleChangeProviderSelect = (e) => {
        const { value } = e.target
        obtenerActividadPorVehiculo(null, value)
        return setProviderSelected(value)
    }

    const handleChangeFecha = (e) => {
        const { value } = e.target
        obtenerActividadPorVehiculo(value)
        return setFechaFiltro(value)
    }

    const desplegarMapa = (i) => {
        const valor_nuevo = vehiculos[i].selected ? false : true
        vehiculos[i].selected = valor_nuevo
        return setVehiculos([...[], ...vehiculos])
    }

    const mostrarEstadosSeleccionados = (id) => {
        if(!showModalEstados) return false
        if(id !== vehiculoSeleccionado) return false
        return <Card className='p-3'>
            <Button size="sm" variant="light" className='mb-3' onClick={() => {
                setShowModalEstados(false)
                setVehiculoSeleccionado(false)
                setEstadosSeleccionados([])
            }}><IoIosArrowBack style={{ verticalAlign: "middle" }} /> CERRAR</Button>
            <TablaEstadosCarga estados={estadosSeleccionados} />
        </Card>
    }

    const mostrarResultados = () => {

        try {
            if (loadingVehiculos === true) return <div className="p-3 text-center">
            <img style={{ maxWidth: 200 }} src={`${url_images}/animations/car-tracking.gif`} />
            <h4 style={{ fontWeight: "bold", color: estilo_last_mile.color_primary }}>Cargando operación de vehículos</h4>
        </div>

            const estados_emitidos = vehiculos.reduce((prev, next) => {
                let conteo = 0
                if (next.estados) {
                    if (Array.isArray(next.estados) !== false) {
                        conteo = next.estados.length
                    }
                }
                return prev + conteo
            }, 0)

            const ordenes_no_entregadas = vehiculos.reduce((prev, next) => {
                const incidencias = calcularIncidencias(next.estados)
                return prev + incidencias
            }, 0)
            
            const ordenes_pendientes = vehiculos.reduce((prev, next) => {
                let conteo = 0
                if (next.ordenes_pendientes) {
                    if (Array.isArray(next.ordenes_pendientes) !== false) {
                        conteo = next.ordenes_pendientes.length
                    }
                }
                return prev + conteo
            }, 0)

            const estados_emitidos_finalizados = vehiculos.reduce((prev, next) => {
                let conteo = 0
                if (next.estados) {
                    if (Array.isArray(next.estados) !== false) {
                        conteo = next.estados.filter(e => e.finalizar === true).length
                    }
                }
                return prev + conteo
            }, 0)

            const ordenes_actualizadas = []
            for (const veh of vehiculos) {
                for (const estado of veh.estados) {
                    if (ordenes_actualizadas.includes(estado.idrecurso)) continue
                    ordenes_actualizadas.push(estado.idrecurso)
                }
            }

            return <div >
                <Card className='p-3 border-0 text-primary'>
                    <Row>
                        <Col className='p-3 m-3 rounded shadow-sm' style={{ border: 'solid', borderColor: '#d6d6d6', borderWidth: 4 }}>
                            <OverlayTrigger
                                placement={'top'}
                                overlay={
                                    <Tooltip>Vehículos que enviaron al menos una actualización de estado</Tooltip>
                                }><h6 style={{ fontSize: 14, fontWeight: 700 }} className='mb-0'>Vehículos <i className="fa-solid fa-circle-question text-help"></i></h6></OverlayTrigger>
                            <div className='d-flex justify-content-between px-3'>
                                <div>
                                    <h5 className='mt-2' style={{ fontSize: 30, fontWeight: 900 }}>{vehiculos.length}</h5>
                                </div>
                                <div>
                                    <i className="fa-solid fa-truck mt-2" style={{ fontSize: 40 }}></i>
                                </div>
                            </div>
                        </Col>
                        {/* <Col className='p-3 m-3 rounded shadow-sm' style={{ border: 'solid', borderColor: '#d6d6d6', borderWidth: 4 }}>
                            <OverlayTrigger
                                placement={'top'}
                                overlay={
                                    <Tooltip>Actualizaciones de estado enviados por el app conductor o sistema web</Tooltip>
                                }><h6 style={{ fontSize: 14, fontWeight: 700 }} className='mb-0'>Estados emitidos <i className="fa-solid fa-circle-question text-help"></i></h6></OverlayTrigger>
                            <div className='d-flex justify-content-between px-3'>
                                <div>
                                    <h5 className='mt-2' style={{ fontSize: 30, fontWeight: 900 }}>{estados_emitidos}</h5>
                                </div>
                                <div>
                                    <i className="fa-solid fa-rotate mt-2" style={{ fontSize: 40 }}></i>
                                </div>
                            </div>
                        </Col> */}
                        {/* <Col className='p-3 m-3 rounded shadow-sm' style={{ border: 'solid', borderColor: '#d6d6d6', borderWidth: 4 }}>
                            <OverlayTrigger
                                placement={'top'}
                                overlay={
                                    <Tooltip>Órdenes que tuvieron algún tipo de gestión</Tooltip>
                                }><h6 style={{ fontSize: 14, fontWeight: 700 }} className='mb-0'>Órdenes actualizadas <i className="fa-solid fa-circle-question text-help"></i></h6></OverlayTrigger>
                            <div className='d-flex justify-content-between px-3'>
                                <div>
                                    <h5 className='mt-2' style={{ fontSize: 30, fontWeight: 900 }}>{ordenes_actualizadas.length}</h5>
                                </div>
                                <div>
                                    <i className="fa-solid fa-file-circle-check mt-2" style={{ fontSize: 40 }}></i>
                                </div>
                            </div>
                        </Col> */}
                        <Col  className='p-3 m-3 rounded shadow-sm' style={{ border: 'solid', borderColor: '#d6d6d6', borderWidth: 4 }}>
                            <OverlayTrigger
                                placement={'top'}
                                overlay={
                                    <Tooltip>Órdenes pendientes</Tooltip>
                                }><h6 style={{ fontSize: 14, fontWeight: 700 }} className='mb-0'>Órdenes pendientes <i className="fa-solid fa-circle-question text-help"></i></h6></OverlayTrigger>
                            <div className='d-flex justify-content-between px-3'>
                                <div>
                                    <h5 className='mt-2' style={{ fontSize: 30, fontWeight: 900 }}>{ordenes_pendientes}</h5>
                                </div>
                                <div>
                                    <i className="fa-regular fa-thumbs-up mt-2" style={{ fontSize: 40 }}></i>
                                </div>
                            </div>
                        </Col>
                        <Col  className='p-3 m-3 rounded shadow-sm' style={{ border: 'solid', borderColor: '#d6d6d6', borderWidth: 4 }}>
                            <OverlayTrigger
                                placement={'top'}
                                overlay={
                                    <Tooltip>Órdenes no entregadas</Tooltip>
                                }><h6 style={{ fontSize: 14, fontWeight: 700 }} className='mb-0'>Órdenes no entregadas <i className="fa-solid fa-circle-question text-help"></i></h6></OverlayTrigger>
                            <div className='d-flex justify-content-between px-3'>
                                <div>
                                    <h5 className='mt-2' style={{ fontSize: 30, fontWeight: 900 }}>{ordenes_no_entregadas}</h5>
                                </div>
                                <div>
                                    <i className="fa-regular fa-thumbs-up mt-2" style={{ fontSize: 40 }}></i>
                                </div>
                            </div>
                        </Col>
                        <Col  className='p-3 m-3 rounded shadow-sm' style={{ border: 'solid', borderColor: '#d6d6d6', borderWidth: 4 }}>
                            <OverlayTrigger
                                placement={'top'}
                                overlay={
                                    <Tooltip>Órdenes completadas</Tooltip>
                                }><h6 style={{ fontSize: 14, fontWeight: 700 }} className='mb-0'>Órdenes completadas <i className="fa-solid fa-circle-question text-help"></i></h6></OverlayTrigger>
                            <div className='d-flex justify-content-between px-3'>
                                <div>
                                    <h5 className='mt-2' style={{ fontSize: 30, fontWeight: 900 }}>{estados_emitidos_finalizados}</h5>
                                </div>
                                <div>
                                    <i className="fa-regular fa-thumbs-up mt-2" style={{ fontSize: 40 }}></i>
                                </div>
                            </div>
                        </Col>
                    </Row>

                </Card>

                <div>
                <Accordion >              
                {
                    vehiculos.map((veh, iv) => {
                        let titulo_fecha = fechaATextoSimple(fechaFiltro)
                        const incidencias = calcularIncidencias(veh.estados)

                        let ordenes_individuales = []
                        for (const estado of veh.estados) {
                            if (ordenes_individuales.includes(estado.idrecurso)) continue
                            ordenes_individuales.push(estado.idrecurso)
                        }

                        const horarios = horas.map((hora, ih) => {
                            const estatuses = veh.estados ? veh.estados : []
                            const estados = estatuses.filter(e => {
                                const fecha = DateTime.fromISO(e.fecha).setZone(timezone)
                                return hora.num === fecha.hour
                            })
                            if(hora.num < 6 || hora.num > 22){
                                if(estados.length < 1) return false
                            }
                            return hora
                        }).filter(e => e)

                        let estados_unicos = []

                        for( const estado of veh.estados ){
                            const i = estados_unicos.findIndex(e => e.codigo_estado === estado.codigo_estado)
                            if(i < 0){
                                estados_unicos.push({
                                    codigo_estado: estado.codigo_estado,
                                    estado_color: estado.estado_color,
                                    titulo: estado.estado,
                                    cantidad: 1
                                })
                            } else {
                                estados_unicos[i].cantidad = estados_unicos[i].cantidad + 1
                            }
                        }

                        return <Card className="p-0" >
                                <Accordion.Toggle as={Card.Header} style={{ padding: '8px 0px', fontSize: 12, backgroundColor: 'white' }} eventKey={veh._id}>
                                        <Row className='p-3 hover'>
                                            <Col className='mb-0'>
                                                <label className='form-control-label mb-0'><FaTruckFront /> Vehículo</label>
                                                <h6 style={{ fontWeight: "bold" }} className='mb-0'>{veh.modelo} · {veh.patente ? veh.patente.toUpperCase() : false}</h6>
                                            </Col>
                                            <Col className='mb-0'>
                                            <label className='form-control-label mb-0'><FaUserAlt /> Conductor</label>
                                            <h6 className='mb-0' style={{ fontWeight: "bold" }}>{veh.nombres} {veh.apellidos}</h6>
                                            </Col>
                                            <Col className='mb-0'>
                                                <label className='form-control-label mb-0'><FaMobile /> Móvil</label>
                                                <h6 style={{ fontWeight: "bold" }} className='mb-0'>{veh.phone}</h6>
                                            </Col>
                                            {/* <Col className='mb-0'>
                                                <label className='form-control-label mb-0'><IoSend /> Eventos emitidos</label>
                                                <h6 style={{ fontWeight: "bold" }} className='mb-0'>{veh.estados.length}</h6>
                                            </Col> */}
                                            <Col className='mb-0'>
                                                <label className='form-control-label mb-0'><FiBox /> Órdenes pendientes</label>
                                                <h6 style={{ fontWeight: "bold" }} className='mb-0'>{veh.ordenes_pendientes ? veh.ordenes_pendientes.length : <TagSinDatos /> }</h6>
                                            </Col>                                            
                                            <Col className='mb-0'>
                                                <label className='form-control-label mb-0'><MdError /> Órdenes no entregadas</label>
                                                <h6 style={{ fontWeight: "bold" }} className='mb-0'>{incidencias}</h6>
                                            </Col>                                            
                                            <Col className='mb-3'>
                                                <label className='form-control-label mb-0'><FaCheckCircle /> Órdenes completadas</label>
                                                <h6 style={{ fontWeight: "bold" }} className='mb-0'>{veh.estados.filter(e => e.finalizar === true).length}</h6>
                                            </Col>
                                            <Col xs={12} className='mb-0'>
                                            {
                                                estados_unicos.map((estado,ie) => {
                                                        return <Badge key={`est-${ie}`} style={{ textTransform: "uppercase", fontSize: 12, background: estado.estado_color, color: "white" }} className='mb-0 mr-3 pr-3 pl-3' >{estado.cantidad} {estado.titulo}</Badge>
                                                })
                                            }
                                            </Col>
                                            {/* <Col className='mb-0'>
                                                <label className='form-control-label mb-0'><FaFileAlt /> Órdenes gestionadas</label>
                                                <h6 style={{ fontWeight: "bold" }} className='mb-0'>{ordenes_individuales.length}</h6>
                                            </Col> */}
                                        </Row>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={veh._id}>
                                    <Card.Body className='p-3 shadow-sm' style={{ backgroundColor: "#f3f3f3", borderRadius: 5 }}>
                                
                                        <h6 className='mb-0 text-primary' style={{ fontSize: 16, fontWeight: 700 }}>Fecha de registros: {titulo_fecha}</h6>
                                        <p className='mb-3 text-primary' style={{ fontSize: 12 }}><i class="fa-solid fa-mobile-screen-button"></i> A continuación se muestran los datos obtenidos de eventos enviados a través de app conductor o web. correspondientes a este vehículo</p>

                                        <div className="pb-3">
                                            <Tabs defaultActiveKey="1" id="uncontrolled-tab-example" className='p-0 m-0 shadow-sm' >
                                                <Tab eventKey="1" title="Vista general" className='border rounded-0 p-4 bg-white shadow-sm'>
                                                    {/* <h6 style={{ fontSize: 14, fontWeight: 700 }} className='mb-3'>Total asignaciones: {veh.total_asignaciones}</h6> */}
                                                    <Row>
                                                        <Col>
                                                            <Card className='p-3 mb-3 text-center text-primary shadow-sm' style={{  }}>
                                                                <h6 className='mb-0' style={{ fontWeight: 900, fontSize: 25 }}><LuBox style={{ verticalAlign: "middle" }} /> {veh.ordenes_pendientes ? veh.ordenes_pendientes.length : <TagSinDatos /> }</h6>
                                                                <h6 style={{ fontSize: 14, fontWeight: 700 }} className='mb-0'>Órdenes pendientes</h6>
                                                            </Card>
                                                        </Col>
                                                        {/* <Col>
                                                            <Card className='p-3 mb-3 text-center text-primary shadow-sm' style={{  }}>
                                                                <h6 className='mb-0' style={{ fontWeight: 900, fontSize: 25 }}><CiMobile3 style={{ verticalAlign: "middle" }} /> {veh.estados.length}</h6>
                                                                <h6 style={{ fontSize: 14, fontWeight: 700 }} className='mb-0'>Estados emitidos</h6>
                                                            </Card>
                                                        </Col> */}
                                                        {/* <Col>
                                                            <Card className='p-3 mb-3 text-center text-primary shadow-sm' style={{ }}>
                                                                <h6 className='mb-0' style={{ fontWeight: 900, fontSize: 25 }}><LuBox style={{ verticalAlign: "middle" }} /> {ordenes_individuales.length}</h6>
                                                                <h6 style={{ fontSize: 14, fontWeight: 700 }} className='mb-0'>Órdenes actualizadas</h6>
                                                            </Card>
                                                        </Col> */}
                                                        <Col>
                                                            <Card className='p-3 mb-3 text-center text-primary shadow-sm' style={{ }}>
                                                                <h6 className='mb-0' style={{ fontWeight: 900, fontSize: 25 }}><IoCheckboxOutline style={{ verticalAlign: "middle" }} /> {veh.estados.filter(e => e.finalizar === true).length}</h6>
                                                                <h6 style={{ fontSize: 14, fontWeight: 700 }} className='mb-0'>Órdenes completadas</h6>
                                                            </Card>
                                                        </Col>
                                                    </Row>

                                                    {/* <Row className='align-items-end' style={{ fontWeight: 700 }}> */}
                                                    <div style={{ padding: "16px 16px 0px 16px", backgroundColor: "#f1f1f1" }}>
                                                        {
                                                            horarios.map((hora, ih) => {
                                                                const porcentaje = 100 / horarios.length
                                                                let titulo_estado = `Estados enviados durante las ${hora.text}`
                                                                // if ((ih + 1) < horas.length) {
                                                                //     titulo_estado = `${titulo_estado} y las ${horas[ih + 1].text}`
                                                                // } else {
                                                                //     titulo_estado = `${titulo_estado} y las 00:00 del dia siguiente`
                                                                // }
                                                                const estados = veh.estados.filter(e => {
                                                                    const fecha = DateTime.fromISO(e.fecha).setZone(timezone)
                                                                    return hora.num === fecha.hour
                                                                })

                                                                let codigos = []

                                                                for( const estado of estados ){
                                                                    const i = codigos.findIndex(c => c.codigo_estado === estado.codigo_estado)
                                                                    if(i < 0){
                                                                        codigos.push({
                                                                            codigo_estado: estado.codigo_estado,
                                                                            estado_color: estado.estado_color,
                                                                            titulo: estado.estado,
                                                                            estados: [estado]
                                                                        })
                                                                    } else {
                                                                        codigos[i].estados.push(estado)
                                                                    }
                                                                }

                                                                if(hora.num < 6 || hora.num > 22){
                                                                    if(codigos.length < 1) return false
                                                                }

                                                                return <div style={{ width: `${porcentaje}%`, display: 'inline-block' }}>
                                                                    <div style={{ marginBottom: 15 }}>
                                                                        {
                                                                            codigos.map(e => {
                                                                                return <div style={{ marginBottom: 0, lineHeight: 1 }}>
                                                                                    <OverlayTrigger
                                                                                        placement={'top'}
                                                                                        overlay={
                                                                                            <Tooltip>Toca para visualizar</Tooltip>
                                                                                        }
                                                                                    >
                                                                                        {/* <i style={{ fontSize: 11, color: e.estado_color }} onClick={() => {
                                                                                            setEstadosSeleccionados(estados)
                                                                                            setShowModalEstados(e.estados)
                                                                                        }} className="fas fa-circle hover">{e.estados.length}</i> */}
                                                                                        <Badge onClick={() => {
                                                                                            setEstadosSeleccionados([...[], ...e.estados])
                                                                                            setVehiculoSeleccionado(veh._id)
                                                                                            setShowModalEstados(true)
                                                                                        }} style={{ background: e.estado_color, color: "white" }} className='mb-2 hover' >{e.estados.length}</Badge>
                                                                                    </OverlayTrigger>
                                                                                </div>
                                                                            })
                                                                        }
                                                                        {/* {estados.map(e => {
                                                                            return <div style={{ marginBottom: 0, lineHeight: 1 }}>
                                                                                <OverlayTrigger
                                                                                    placement={'top'}
                                                                                    overlay={
                                                                                        <Tooltip> {fechaATexto(e.fecha)}</Tooltip>
                                                                                    }
                                                                                >
                                                                                    <i style={{ fontSize: 11, color: e.estado_color }} onClick={() => seleccionarEstado(e)} className="fas fa-circle hover"></i>
                                                                                </OverlayTrigger>
                                                                            </div>
                                                                        })} */}
                                                                    </div>
                                                                    <OverlayTrigger
                                                                        placement={'top'}
                                                                        overlay={
                                                                            <Tooltip> {estados.length} {titulo_estado}</Tooltip>
                                                                        }
                                                                    >
                                                                        <p style={{ fontSize: "0.5em" }}>{hora.text}</p>
                                                                    </OverlayTrigger>

                                                                </div>
                                                            })

                                                        }
                                                        </div>
                                                    {/* </Row> */}

                                                    {mostrarEstadosSeleccionados(veh._id)}

                                                    <Button variant="primary" className="w-100 mt-2 mb-3 shadow-sm" style={{ fontSize: 14 }} onClick={() => desplegarMapa(iv)}><BiMap size={20} />{veh.selected ? <b> CERRAR MAPA</b> : <b> VER MAPA</b>}</Button>
                                                    {veh.selected === true ? veh.estados.length > 0 ? <MapaEstadosCarga estados={veh.estados} /> : <SinDatos /> : false}
                                                </Tab>
                                                <Tab eventKey="2" title="Cronología de estados" className='border rounded-0 p-4 bg-white shadow-sm'>
                                                    <div style={{ maxHeight: 300, padding: 10, overflowY: "scroll", background: "" }}>
                                                        {veh.estados.length > 0 ? <div>
                                                            <TablaEstadosCarga estados={veh.estados} />
                                                        </div> : <SinDatos />}
                                            
                                                    </div>
                                                </Tab>
                                            </Tabs>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                        </Card>
                    })
                }
                </Accordion> 
                </div>
            </div>

        } catch (error) {
            return <SinDatos />
        }


    }

    return <div>
        <Modal show={showModal} size="xl" onHide={() => handleClose()} centered >
            <Modal.Header closeButton>
                <Modal.Title>Detalles del pedido</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    loadingDetalle ? <div>
                        <Spinner animation='border' />
                        <h6 className='mb-0'>Cargando datos, espere un momento...</h6>
                    </div> : <DetailFull actualizarEstadoListado={(estado_entrega, sub_estado_entrega) => actualizarEstadoListado(estado_entrega, sub_estado_entrega)} token={token} pedido={pedidoSeleccionado} />
                }
            </Modal.Body>
        </Modal>

        <Row className='d-block m-3'>
            <h4 className='delpa_lastmile_actividad_general text-primary' style={{ fontWeight: 900 }}>SELECCIONA UN DIA DE CONSULTA</h4>
            <Row className=' border-0'>
                <Col xs={4}>
                    <label className='form-control-label text-primary' style={{ fontWeight: 700, fontSize: 18 }}>Desde</label>
                    <InputGroup className="mb-3">
                        <InputGroup.Text className='shadow-sm' id="basic-addon1">
                        { loadingVehiculos ? <Spinner animation='border' size="sm" /> : <MdDateRange />}
                        </InputGroup.Text>
                        <Form.Control
                            disabled={loadingVehiculos}
                            className='form-control shadow-sm' type="date" value={fechaFiltro} onChange={handleChangeFecha}
                        />
                    </InputGroup>
                </Col>
                {
                    providers.length > 0 ? <Col xs={4}>
                        <label className='form-control-label text-primary' style={{ fontWeight: 700, fontSize: 18 }}>Proveedor de transporte</label>
                        <select disabled={loadingVehiculos} className='form-control' value={providerSelected} onChange={handleChangeProviderSelect} >
                            <option value="" >Todos</option>
                            {providers.map((prov, ip) => <option key={`prov-${ip}`} value={prov.rut}>{prov.nombre}</option>)}
                        </select>
                    </Col> : false
                }
                {/* <Col className='row align-items-end mb-3'>
                    <div className=''>
                        <Button className='shadow-sm' variant="primary" onClick={() => obtenerActividadPorVehiculo()}><b>ACTUALIZAR</b></Button>
                    </div>
                </Col> */}
            </Row>
        </Row>


        {mostrarResultados()}

    </div>
}

export default ActividadConductores
