import { useDispatch, useSelector } from "react-redux"
import { urlapi } from "../../lib/backend/data"
import { Table, Button, Spinner } from "react-bootstrap"
import SinDatos from "../general/sin_registros"
import { AiTwotoneDelete } from 'react-icons/ai'
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton"
import { cerrarSesion } from "../../redux/actions/session"
import { toast } from "react-toastify"
import { confirmAlert } from "react-confirm-alert"

const TablaProductos = (props) => {
    const {
        viewType
    } = props
    const { id_orden } = props
    const [ productos, setProductos ] = useState(props.productos ? props.productos : [])
    const [ loading, setLoading ] = useState(false)
    const [ loadingGuardando, setLoadingGuardando ] = useState(false)
    const dispatch = useDispatch()
    const session = useSelector(state => state.miusuario)

    useEffect(() => {
    },[(loading === true)])

    if(Array.isArray(productos) !== true) return <SinDatos />
    if(productos.length < 1) return <SinDatos />

    const guardarCambios = async () => {
        setLoading(true)
        console.log("PRODUCTOS:", productos)
        const enviar = productos.filter(c => c.id_orden).map(campo => {
            campo.id_orden = id_orden
            return campo
        })
        return fetch(`${urlapi}/ordenes/productos/orden`,{
            method:'PUT',
            body: JSON.stringify(enviar),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if((res) !== false){   
                setProductos(props.productos)          
                    toast.success("Cambios guardados!")
                }
              setTimeout(function(){
                return setLoading(false)
             }, 800);
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingGuardando(false)
        })
    }

    // const agregarProductoCrear = () => {
    //     console.log("ID_ORDEN:", id_orden)

    //       setLoading(true)
    //       return fetch(`${urlapi}/ordenes/productos/orden`,{
    //           method:'POST',
    //           body: JSON.stringify({
    //               id_orden
    //           }),
    //           headers: {
    //               'Content-Type':'application/json',
    //               'Authorization': `Bearer: ${session.tokenSession}`
    //           }
    //       })
    //       .then(res => {
    //           if(res.status === 401) return dispatch(cerrarSesion())
    //           return res.json()
    //       })
    //       .then(res => {
    //           if(!res){
    //               toast.error('Sin datos')
    //               return setLoading(false)
    //           } else if(res.errorMessage){
    //               toast.error(res.errorMessage)
    //               return setLoading(false)
    //           } else if((res) !== false){
    //                    productos.push(res)           
    //          toast.success("Creado con exito")
    //           }
    //           return setLoading(false)
    //       })
    //       .catch(error => {
    //           toast.error("Error al consultar la información, intente nuevamente")
    //           return setLoading(false)
    //       })
    //   }


    const handleChangePeso = (e) => {
        const { name, value } = e.target
        const id =  name
        const i = productos.findIndex(campo => campo._id === id)
        if(i < 0) return false
        productos[i].peso = value
        if(productos[i].peso === "" ) productos[i].peso = 0
        console.log(productos)
        return setProductos([...[], ...productos])
    }
    const handleChangeAlto = (e) => {
        const { name, value } = e.target
        const id =  name
        const i = productos.findIndex(campo => campo._id === id)
        if(i < 0) return false
        productos[i].alto = value
        if(productos[i].alto === "" ) productos[i].alto = 0
        console.log(productos)
        return setProductos([...[], ...productos])
    }
    const handleChangeLargo = (e) => {
        const { name, value } = e.target
        const id =  name
        const i = productos.findIndex(campo => campo._id === id)
        if(i < 0) return false
        productos[i].largo = value
        if(productos[i].largo === "" ) productos[i].largo = 0
        console.log(productos)
        return setProductos([...[], ...productos])
    }
    const handleChangeAncho = (e) => {
        const { name, value } = e.target
        const id =  name
        const i = productos.findIndex(campo => campo._id === id)
        if(i < 0) return false
        productos[i].ancho = value
        if(productos[i].ancho === "" ) productos[i].ancho = 0
        console.log(productos)
        return setProductos([...[], ...productos])
    }
    const handleChangeDescription = (e) => {
        const { name, value } = e.target
        const id =  name
        const i = productos.findIndex(campo => campo._id === id)
        if(i < 0) return false
        productos[i].description = value
        console.log(productos)
        return setProductos([...[], ...productos])
    }
    const handleChangeQuantity = (e) => {
        const { name, value } = e.target
        const id =  name
        const i = productos.findIndex(campo => campo._id === id)
        if(i < 0) return false
        productos[i].quantity = value
        if(productos[i].quantity === "" ) productos[i].quantity = 0
        console.log(productos)
        return setProductos([...[], ...productos])
    }
    const handleChangePrice = (e) => {
        const { name, value } = e.target
        const id =  name
        const i = productos.findIndex(campo => campo._id === id)
        if(i < 0) return false
        productos[i].price = value
        if(productos[i].price === "" ) productos[i].price = 0
        console.log(productos)
        return setProductos([...[], ...productos])
    }
    const handleChangeCodigo = (e) => {
        const { name, value } = e.target
        const id =  name
        const i = productos.findIndex(campo => campo._id === id)
        if(i < 0) return false
        productos[i].codigo_item = value
        console.log(productos)
        return setProductos([...[], ...productos])
    }

    const solicitarEliminar = (pos) => {
        return confirmAlert({
            title: `¿Estás seguro?`,
            message: `Estás a punto de eliminar esta orden, esta acción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => eliminar(pos)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    const eliminar = (pos) => {        
        const id = productos[pos]._id
        console.log("id a eliminar:",  id)
          setLoading(true)
          return fetch(`${urlapi}/ordenes/productos/orden`,{
              method:'DELETE',
              body: JSON.stringify({
                  id
              }),
              headers: {
                  'Content-Type':'application/json',
                  'Authorization': `Bearer: ${session.tokenSession}`
              }
          })
          .then(res => {
              if(res.status === 401) return dispatch(cerrarSesion())
              return res.json()
          })
          .then(res => {
              if(!res){
                  toast.error('Sin datos')
                  return setLoading(false)
              } else if(res.errorMessage){
                  toast.error(res.errorMessage)
                  return setLoading(false)
              } else if((res) !== false){
                if(props.onDelete) props.onDelete(pos)  
                setTimeout(function(){
                    toast.success("Eliminado con Exito")
                 }, 1000);           
                
               
              }
              setTimeout(function(){
                return setLoading(false)
             }, 2000);
             
          })
          .catch(error => {
              toast.error("Error al consultar la información, intente nuevamente")
              return setLoading(false)
          })       
    }

    if(viewType === "simple"){
        return <div>
            <Table bordered hover striped className="bg-white">
            <thead className="text-primary" >
                <tr>
                    <th style={{fontWeight:900}}>DESCRIPCIÓN</th>
                    <th style={{fontWeight:900}}>CÓDIGO</th>
                    <th style={{fontWeight:900}}>ANCHO</th>
                    <th style={{fontWeight:900}}>ALTO</th>
                    <th style={{fontWeight:900}}>LARGO</th>
                    <th style={{fontWeight:900}}>PESO</th>
                    <th style={{fontWeight:900}}>CANTIDAD</th>
                    <th style={{fontWeight:900}}>PRECIO</th>
                </tr>
            </thead>
            <tbody>
                {
                    productos.map((producto,i) => {
                        return <tr key={`producto-${i}`}>
                            <th>{producto.description ? producto.description : '--'}</th>
                            <th>{producto.codigo_item ? producto.codigo_item : '--'}</th>
                            <th>{producto.ancho ? producto.ancho : '--'}</th>
                            <th>{producto.alto ? producto.alto : '--'}</th>
                            <th>{producto.largo ? producto.largo : '--'}</th>
                            <th>{producto.peso ? producto.peso : '--'}</th>
                            <th>{producto.quantity ? producto.quantity : '--'}</th>
                            <th>{producto.price ? producto.price : '--'}</th>
                        </tr>
                    })
                }
            </tbody>
        </Table>
        </div>
    } else if(viewType === "ecommerce"){
            return <div>
                <Table bordered hover striped className="bg-white">
                <thead className="text-primary" >
                    <tr>
                        <th style={{fontWeight:900}}>DESCRIPCIÓN</th>
                        <th style={{fontWeight:900}}>CÓDIGO</th>
                        <th style={{fontWeight:900}}>PESO</th>
                        <th style={{fontWeight:900}}>CANTIDAD</th>
                        <th style={{fontWeight:900}}>PRECIO</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        productos.map((producto,i) => {
                            return <tr key={`producto-${i}`}>
                                <th>{producto.description ? producto.description : '--'}</th>
                                <th>{producto.codigo_item ? producto.codigo_item : '--'}</th>
                                <th>{producto.peso ? producto.peso : '--'}</th>
                                <th>{producto.quantity ? producto.quantity : '--'}</th>
                                <th>{producto.price ? producto.price : '--'}</th>
                            </tr>
                        })
                    }
                </tbody>
            </Table>
            </div>
    }

    return <>

{/* <Button size='sm' variant='outline-primary mb-3 mt-3' onClick={() => agregarProductoCrear()}><i className="fa-solid fa-circle-plus"></i> AGREGAR</Button>  */}


    {   loading === true ? <Spinner animation='border' className='mt-5 ml-3' /> : 
     <div className="table-responsive">
        <Table bordered hover striped className="bg-white shadow">
            <thead className="text-primary" >
                <tr>
                    <th style={{fontWeight:900}}>ACCIÓN</th>
                    <th style={{fontWeight:900}}>DESCRIPCIÓN</th>
                    <th style={{fontWeight:900}}>CÓDIGO</th>
                    <th style={{fontWeight:900}}>ANCHO</th>
                    <th style={{fontWeight:900}}>ALTO</th>
                    <th style={{fontWeight:900}}>LARGO</th>
                    <th style={{fontWeight:900}}>PESO</th>
                    <th style={{fontWeight:900}}>CANTIDAD</th>
                    <th style={{fontWeight:900}}>PRECIO</th>
                </tr>
            </thead>
            <tbody>
                {
                    productos.map((producto,i) => {
                        return <tr key={`producto-${i}`}>
                            <th style={{ textAlign: "center" }}><Button onClick={() => solicitarEliminar(i)} size='sm' variant='danger' className="shadow-sm py-0 mt-2"><b>ELIMINAR</b></Button></th>
                            <th><input className="form-control shadow-sm" name={producto._id} onChange={handleChangeDescription}   defaultValue={producto.description} /></th>
                            <th><input className="form-control shadow-sm" name={producto._id} onChange={handleChangeCodigo} defaultValue={producto.codigo_item} /></th>
                            <th><input className="form-control shadow-sm" name={producto._id} onChange={handleChangeAncho} type="number" defaultValue={producto.ancho} /></th>
                            <th><input className="form-control shadow-sm" name={producto._id} onChange={handleChangeAlto} type="number" defaultValue={producto.alto} /></th>
                            <th><input className="form-control shadow-sm"  name={producto._id} onChange={handleChangeLargo}type="number"  defaultValue={producto.largo} /></th>
                            <th><input className="form-control shadow-sm" name={producto._id} onChange={handleChangePeso} type="number" defaultValue={producto.peso} /></th>
                            <th><input className="form-control shadow-sm" name={producto._id} onChange={handleChangeQuantity} type="number" defaultValue={producto.quantity} /></th>
                            <th><input className="form-control shadow-sm" name={producto._id} onChange={handleChangePrice} type="number" defaultValue={producto.price} /></th>
                        </tr>
                    })
                }
            </tbody>
        </Table>
        <div className="d-flex justify-content-end">
        { loadingGuardando ? <Spinner animation="border"  className='mt-2 ml-3'/> : <Button size="sm" className="mb-4 shadow-sm w-25" variant="primary" onClick={() => guardarCambios()} ><b> GUARDAR CAMBIOS</b></Button> }
        </div>
    </div>
}


</>


}

export default TablaProductos