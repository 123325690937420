import { useState } from "react"
import { Button, Col, Row, Spinner } from "react-bootstrap"
import { toast } from "react-toastify"
import { urlapi } from "../../../lib/backend/data"
import { useDispatch, useSelector } from "react-redux"
import ProveedoresListadoProveedores from "../../proveedores_transporte/enlace/listado_proveedores"
import { cerrarSesion } from "../../../redux/actions/session"

const BoxAsignacionRuta = (props) => {
    const {
        id_ruta
    } = props
    const [ codigoProveedor, setCodigoProveedor ] = useState("")
    const [ loading, setLoading ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const dispatch = useDispatch()

    const invitarGestionRuta = async (cond)=>{
        if(!codigoProveedor) return toast.error("Ingresa el código de proveedor")
        setLoading(true)
        return fetch(`${urlapi}/rutas/invitar-gestionar`,{
            method:'POST',
            body: JSON.stringify({
                id_ruta,
                codigo_proveedor: codigoProveedor
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                toast.success("Invitación enviada exitosamente")
                setTimeout(() => {
                    window.location.reload()
                }, 500);
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }


    const handleChange = (e) => {
        const { value } = e.target
        return setCodigoProveedor(value)
    }

    const onClickEmpresa = (data) => {
        setCodigoProveedor(data.codigo)
    }

    return <div>
        <Row>
            <Col className="mb-3">
                <label className="form-control-label">Selecciona proveedor</label>
                <ProveedoresListadoProveedores hightLightCodigo={codigoProveedor} onClick={(data) => onClickEmpresa(data)} displayAll={true} condicion_default={{ propietario_cliente: session.data.propietario }} />
            </Col >
            <Col xs={12} className="mb-3">
               { loading ? <Spinner animation="border" /> : <Button variant="success" size="small" onClick={() => invitarGestionRuta()}>ASIGNAR RUTA</Button> }
            </Col>
        </Row>

    </div>
}

export default BoxAsignacionRuta