import { useState } from "react"
import Select from 'react-select'
import SelectorCamposPersonalizados from "../campos-personalizados/selector"
import CargaImagenes from "../general/carga_imagenes"
import { Badge, Button, Card, Col, Modal, Row } from "react-bootstrap"
import { FaArrowDown, FaArrowUp, FaPlus, FaRegTrashAlt } from "react-icons/fa"
import { estilo_last_mile } from "../../lib/global/styles"
import { components } from "./data"
import SelectorRangoFechas from "../general/selector_rango_fechas"
import SelectorBodegaDestino from "../bodega/bodegas/selector_bodega_destino"
import SelectorBodega from "../bodega/bodegas/selector_bodega"

const VisualizadorContenido = (props) => {
    const {
        posicion,
        data,
        content,
        layout
    } = props
    const [ showModalOpcion, setshowModalOpcion ] = useState(false)
    const [ configSelected, setConfigSelected ] = useState(false)
    const [ showModlConfig, setShowModlConfig ] = useState(false)
    const fields_products = [
        "description",
        "codigo_item",
        "peso",
        "alto",
        "ancho",
        "largo",
        "price",
        "quantity",
        "campos personalizados"
        ]
    const fields_destinatario = [
        "nombre",
        "email",
        "telefono",
        ]
    const fields_remitente = [
        "nombre",
        "email",
        "telefono",
        ]
    const fields_conductor = [
        "nombre",
        "telefono",
        "vehiculo",
        "año",
        "patente",
        ]


    const modificarInformacion = (value) => {
        if(props.onChange) props.onChange({ ...configSelected,  value })
        setConfigSelected(prev => ({ ...prev, value }))
    }
    
    const showCustomComponent = () => {
        if(!configSelected) return false
        if(!configSelected.custom_component) return false

        let defaultValue = null
        if(configSelected.value) defaultValue = configSelected.value

        const custom_components = [
            {
                name: "campos_personalizados",
                component: <div>
                    <h4 className="mb-0" style={{ fontWeight: "bold" }}>Configura los campos personalizados</h4>
                    <p>Selecciona los campos a mostrar, por defecto se muestran todos</p>
                    <SelectorCamposPersonalizados defaultValue={defaultValue} isMulti={true} onChange={(val) => modificarInformacion(val)} />
                </div>
            },
            {
                name: "productos",
                component: <div>
                    <h4 className="mb-0" style={{ fontWeight: "bold" }}>Configura la sección de productos</h4>
                    <p>Selecciona los campos a mostrar, por defecto se muestran todos</p>
                    <Select placeholder="Activa los campos a mostrar" defaultValue={defaultValue} options={fields_products.map(f => ({ value: f, label: f }))} isMulti={true} onChange={(val) => modificarInformacion(val)} />
                </div>
            },
            {
                name: "logotipo",
                component: <div>
                    <h4 className="mb-3" style={{ fontWeight: "bold" }}>Selecciona una imagen</h4>
                    <CargaImagenes onUploaded={(url) => modificarInformacion(url)} />
                    {configSelected.value && <img className="mt-3" src={configSelected.value} alt="imagen" style={{ maxWidth: 100 }} />}
                </div>
            },
            {
                name: "input",
                component: <div>
                    <h4 className="mb-3" style={{ fontWeight: "bold" }}>Escribe un texto</h4>
                    <textarea className="form-control" defaultValue={defaultValue} onChange={(e) => modificarInformacion(e.target.value)} />
                </div>
            },
            {
                name: "fechas",
                component: <div>
                    <h4 className="mb-0" style={{ fontWeight: "bold" }}>Selección de fechas</h4>
                    <p>Selecciona el rango de fechas mínima y máxima de entrega</p>
                    <SelectorRangoFechas titulo_label='Compromiso de entrega' defaultValue={defaultValue} onChange={(val) => modificarInformacion(val)} />
                </div>
            },
            {
                name: "origen",
                component: <div>
                    <h4 className="mb-3" style={{ fontWeight: "bold" }}>Origen de la carga</h4>
                    <SelectorBodega isMulti={false} defaultValue={defaultValue} onChange={(val) => modificarInformacion(val)} />
                </div>
            },
            {
                name: "destino",
                component: <div>
                    <h4 className="mb-3" style={{ fontWeight: "bold" }}>Destino de la carga</h4>
                    <SelectorBodegaDestino isMulti={true} defaultValue={defaultValue} onChange={(val) => modificarInformacion(val)} />
                </div>
            },
            {
                name: "remitente",
                component: <div>
                    <h4 className="mb-3" style={{ fontWeight: "bold" }}>Información del remitente</h4>
                    <Select placeholder="Activa los campos a mostrar" defaultValue={defaultValue} options={fields_remitente.map(f => ({ value: f, label: f }))} isMulti={true} onChange={(val) => modificarInformacion(val)} />
                </div>
            },
            {
                name: "destinatario",
                component: <div>
                    <h4 className="mb-3" style={{ fontWeight: "bold" }}>Información del destinatario</h4>
                    <Select placeholder="Activa los campos a mostrar" defaultValue={defaultValue} options={fields_destinatario.map(f => ({ value: f, label: f }))} isMulti={true} onChange={(val) => modificarInformacion(val)} />
                </div>
            },
            {
                name: "conductor",
                component: <div>
                    <h4 className="mb-3" style={{ fontWeight: "bold" }}>Información del conductor</h4>
                    <Select placeholder="Activa los campos a mostrar" defaultValue={defaultValue} options={fields_conductor.map(f => ({ value: f, label: f }))} isMulti={true} onChange={(val) => modificarInformacion(val)} />
                </div>
            }
        ]
        const i = custom_components.findIndex(c => c.name === configSelected.custom_component)
        if(i < 0) return false
        return custom_components[i].component
    }
    console.log(configSelected, "configSelected")
    const agregar = (item) => {
        if(props.onAdd) props.onAdd(item)
            setshowModalOpcion(false)
    }

    const mover = (action, pos) => {
        if(props.onMove) props.onMove(action, pos)
    }

    const showValConfig = (val, config) => {
        if(config?.name === "logotipo") return <img src={val} alt="imagen" style={{ width: 100 }} />
        if(config?.name === "fechas") return <p className="mb-0">"soy las fechas"</p>

        let key = config?.slug ? config.slug : config.name
        const content = layout[ key ] ? layout[ key ]() : false
        if(content) return content

        return <div>{ val ? <p className="mb-0">{val}</p> : false }</div>
    }
    const mostrarContenido = () => {
        if(!content) return false
        if(typeof content !== "object") return false
        // if(!content[posicion]) return false
        return <div>
            {
                content.map((item, i) => {
                    let val = ''
                    // CONDICIONES PARA MANEJO DE CONTENIDO
                    //STRING
                    if(typeof item.value === "string" ) val = item.value
                    //FECHA
                    if(Object.prototype.toString.call(item.value) === '[object Date]') {
                        val = item.value.toLocaleDateString();
                    }
                    //RANGO DE FECHAS
                    if(Array.isArray(item.value) && item.value.every(date => Object.prototype.toString.call(date) === '[object Date]')) {
                        val = item.value.map(date => date.toLocaleDateString()).join(' - ');
                    }
                    //MAPA
                    if(Array.isArray(item.value)) val = item.value.map((item,ii) => {
                        let valor = item
                        if(typeof valor === "object") valor = item.label
                        return <Badge variant="dark" style={{ textTransform: "uppercase" }} className="mr-2" key={`-${ii}`} >{valor}</Badge>
                    })

                    if(posicion !== item.posicion) return false
                    return <div>
                        {/* <p className="mb-0" style={{ textTransform: "uppercase" }} ><b style={{ fontWeight: "bold" }} >{item.name}</b></p> */}
                        {showValConfig(val, item)}
                        </div>
                })
            }
        </div>
    }

    const removerContenido = (i) => {
        if(props.onRemove) props.onRemove(i)
    }

    const mostrarOpciones = (opciones, key, title) => {
        if(!opciones) return false
        if(!Array.isArray(opciones)) return false
        if(opciones.length < 1) return false
        return <div>
            <h5 style={{ fontWeight: "bold"}}>{title}</h5>
            <Row>
            {
                opciones.map(( item, it) => {
                    const payload = {
                        ...item,
                        type: key
                    }
                    return <Col md={4}>
                        <Card className="p-4 hover hover mb-3 text-center" onClick={() => agregar(payload)}>
                        <p className="mb-0">{item.title ? item.title : item.name}</p>
                        </Card>
                    </Col>
                })
            }
            </Row>
        </div>
    }

    return <div>
        {mostrarContenido()}
    </div>
}

export default VisualizadorContenido