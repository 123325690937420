import Structure from "../Structure"
import { useEffect, useState } from "react";
import { rutas } from "../../lib/routes/routes";
import { Link } from "react-router-dom";
import { main_data } from "./datos";
import { urlapi } from "../../lib/backend/data";
import { useDispatch, useSelector } from "react-redux";
import { ReactSortable } from "react-sortablejs";
import { FaRegHandPointer, FaRegTrashAlt } from "react-icons/fa";
import { HiCursorClick } from "react-icons/hi";
import { Button, Card, Col, Modal, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import { cerrarSesion } from "../../redux/actions/session";
import { toast } from "react-toastify";
import TiposServicioSelector from "../../subComponents/ordenes/tipos_servicio/selector";

const LineaTiempo = (props) => {
    const {
        showCreateButton,
        showImportButton,
        display_full,
        condicion,
    } = props
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(condicion ? condicion : {})
    const [ loadingEstados, setLoadingEstados ] = useState(false)
    const [ loadingLineasTiempo, setLoadingLineasTiempo ] = useState(false)

    const [ tipoServicioSeleccionado, setTipoServicioSeleccionado ] = useState(null)
    const [ estados, setEstados ] = useState([])
    const [ lineaTiempoSeleccionada, setLineaTiempoSeleccionada ] = useState(null)
    const [ showModal, setShowModal ] = useState(false)
    const [ savingLineaTiempo, setSavingLineaTiempo ] = useState(false)
    const [ lineasTiempo, setLineasTiempo ] = useState([])
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const modulo = main_data.module

    useEffect(() => {
        obtenerEstados()
        obtenerLineasTiempo()
    }, [])

    const obtenerEstados = async () => {
        setLoadingEstados(true)
        return fetch(`${urlapi}/${modulo}/details`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray(res.estados) !== false){
                setEstados(res.estados)
            }
            return setLoadingEstados(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingEstados(false)
        })
    }

    const actualizarLineaTiempo = async (linea_tiempo) => {
        setSavingLineaTiempo(true)
        return fetch(`${urlapi}/${modulo}/lineas-tiempo`,{
            method:'PUT',
            body: JSON.stringify({...linea_tiempo, tipo_servicio: tipoServicioSeleccionado?._id }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){

            }
            return setSavingLineaTiempo(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setSavingLineaTiempo(false)
        })
    }

    const obtenerLineasTiempo = async () => {
        setLoadingLineasTiempo(true)
        return fetch(`${urlapi}/${modulo}/lineas-tiempo`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
                setLineasTiempo(res)
            }
            return setLoadingLineasTiempo(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingLineasTiempo(false)
        })
    }

    const seleccionarTipoServicio = (data) => {
        console.log(data)
        const i = lineasTiempo.findIndex(item => item.tipo_servicio === data?._id)
        if( i > -1){
            setLineaTiempoSeleccionada(lineasTiempo[i])
        } else {
            setLineaTiempoSeleccionada({ hitos: [] })
        }
        setTipoServicioSeleccionado(data)
    }

    const agregar = (estado_completo) => {
        return setLineaTiempoSeleccionada(prev => {
            let actual = {...prev}
            actual.hitos.push(estado_completo)
            actualizarLineaTiempo(actual)
            return {...{}, ...actual}
        })
    }

    const detectarEstadosActuales = () => {
        const mostrar_estados = lineaTiempoSeleccionada ? estados.filter(e => e.tipos_servicio.includes(tipoServicioSeleccionado?._id)).filter(e => {
            const ids_seleccionados = lineaTiempoSeleccionada.hitos.map(h => h._id)
            return !ids_seleccionados.includes(e._id)
        }) : estados.filter(e => e.tipos_servicio.includes(tipoServicioSeleccionado?._id))

        return mostrar_estados
    }
    
    const mostrarEstados = () => {
        if(loadingEstados) return <div>
            <Spinner animation="border" />
            <h4 level={5} className="mt-0">Cargando...</h4> </div>

        if(!tipoServicioSeleccionado) return <div>
            <h4 className="mt-0" level={5}>Selecciona un tipo de servicio para ver los estados asociados</h4>
        </div>

        if(estados.length < 1) return <div>
            <h4 className="mt-0" level={5}>El servicio que seleccionaste aún no tiene eventos logísticos asociados dentro del protocólo de entrega</h4>
            <Link to={rutas.estados_carga.slug}><Button >CREAR Y ASOCIAR EVENTOS</Button></Link>
        </div>

        const mostrar_estados = detectarEstadosActuales()

        if(mostrar_estados.length < 1) return <div>
            <h4 className="mt-0" level={5}>No hay más estados disponibles para agregar a la línea de tiempo</h4>
        </div>

        return <div>
            <h4 level={3} className="mt-0 mb-0">{mostrar_estados.length} Estados encontrados</h4>
            {/* <Alert className="mb-3" message={} type="info" /> */}
            <div><HiCursorClick /> A continuación toca el estado para agregarlo a la línea de tiempo</div>
            {
                mostrar_estados.map((estado, index) => {
                    return <div key={`estado-${index}`}>
                        <OverlayTrigger
                            key={estado._id}
                            // trigger="click"
                            overlay={
                                <Tooltip id={'right'}>Toca para agregar a la línea de tiempo</Tooltip>
                            }>
                            <Button level={5} icon={<HiCursorClick />} variant="light" className="mb-3 w-100 " onClick={() => agregar(estado)} >{estado.titulo}</Button>
                        </OverlayTrigger>
                        
                        
                    </div>
                })
            }
        </div>
    }

    const remover = (i) => {
        setLineaTiempoSeleccionada(prev => {
            let actual = {...prev}
            actual.hitos.splice(i, 1)
            actualizarLineaTiempo(actual)
            return {...{}, ...actual}
        })
    }

    const reordenarHitos = (hitos) => {
        setLineaTiempoSeleccionada(prev => {
            let actual = {...prev}
            actual.hitos = hitos
            actualizarLineaTiempo(actual)
            return {...{}, ...actual}
        })
    }

    const mostrarLineaTiempo = () => {
        if(!tipoServicioSeleccionado) return <div>
            <h4 className="mt-0" level={5}>Selecciona un tipo de servicio para ver las líneas de tiempo asociadas</h4>
        </div>
        
        const mostrar_estados = detectarEstadosActuales()
        if(lineaTiempoSeleccionada.hitos.length < 1 ){
            if(mostrar_estados.length < 1) return false
            return <div>
                <h4 className="mt-0" level={3}>Aún no has agregado estados a la línea de tiempo</h4>
            </div>
        }

        return <div>
            <h4 level={3} className="mt-0 mb-0">Esta es tu línea de tiempo para <b>{savingLineaTiempo ? <Spinner animation="border" /> : false } {tipoServicioSeleccionado?.title}</b></h4>
            {/* <Alert className="mb-3" message={} type="info" /> */}

            <div>
            <p className="mb-0"><FaRegHandPointer /> Reordena los estados arrastrandolos hacia arriba y abajo.</p>
            <Button size="sm" className="mb-2" onClick={() => setShowModal(true)}>PREVISUALIZAR</Button></div>

            <ReactSortable list={lineaTiempoSeleccionada.hitos} animation={200} setList={(d) => reordenarHitos(d)}>
            {
                lineaTiempoSeleccionada.hitos.map((hito, index) => {
                    return <div key={`hito-${(index+1)}`}>
                        <Card size="small" className="mb-3 hover p-2">
                        <Row>
                            <Col md={6}><h4 className="mt-0 mb-0" level={5}>{(index+1)} {hito.titulo}</h4></Col>
                            <Col md={6} style={{ textAlign: "right" }}>
                            <Button size="sm" variant="light" onClick={() => remover(index)}><FaRegTrashAlt /> REMOVER</Button>
                            </Col>
                        </Row>
                        </Card>
                    </div>
                })
            }
            </ReactSortable>
        </div>

    }

    const handleCancel = () => setShowModal(false)
    const mostrarModal = () => {

        return <Modal
            title="Esta este es un ejemplo de tu línea de tiempo" 
            show={showModal} 
            // onOk={handleOk} 
            onHide={handleCancel}
            // confirmLoading={loading}
        >
            <div></div>
            {/* <Steps
                current={lineaTiempoSeleccionada?.hitos.length - 1}
                style={{ marginTop: 20 }}
                items={lineaTiempoSeleccionada?.hitos.map( (estado,i) => {
                    return {
                        title: estado.titulo,
                        description: fechaUTCATexto(DateTime.now().plus({ minutes: (i+10) }).toUTC().toISO()),
                        // icon: <FiBox />
                    }
                })}
            /> */}
    </Modal>
    }
    
    const render = () => {
        return <div>
            {mostrarModal()}
            <h4 className="mb-2 mt-0">{main_data.titulo}</h4>
            <p>{main_data.descripcion_2}</p>
            <TiposServicioSelector defaultValue={tipoServicioSeleccionado} titulo="Tipo de servicio" condicion={{ tipo: "servicios" }} module="estadoscarga" key_label="title" key_value="_id" onChangeValue={(data) => seleccionarTipoServicio(data)} />
            <Row gutter={15}>
                <Col md={6}>
                    {mostrarEstados()}
                </Col>
                <Col md={6}>
                    {mostrarLineaTiempo()}
                </Col>
            </Row>
        </div>
    }

    if(display_full === true ){

        const renderizar = <div>
            <Card>{render()}</Card>
        </div>

        return <Structure component={renderizar} />
    } else {
        return render()
    }
}

export default LineaTiempo